import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import store from "./store";
import { persistor } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import defaultTranslation from "./library/language/en/defaultTranslation.json";
import { IdleTimerProviderComponent } from "../src/idleTimer/IdleTimerContext";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      translation: defaultTranslation,
    },
    other: {},
  },
  fallbackLng: "en",
  // missingKeyNoValueFallbackToKey: false,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* <React.StrictMode> */}
        <I18nextProvider i18n={i18next}>
          <IdleTimerProviderComponent>
            <App />
          </IdleTimerProviderComponent>
        </I18nextProvider>
        {/* </React.StrictMode> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
