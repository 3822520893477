import { useRef, useState } from "react";
import ArrowIcon from "../double-down-arrow.svg";

export function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const { title, children } = props;

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`}>
        <a
          style={{ color: "#004e5c", width: "100%" }}
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#SimpleJsonPathModal"
          onClick={props.onClick}
        >
          <p className="accordion__title">{title}</p>
        </a>
        <button
          onClick={toggleAccordion}
          style={{ border: "none", background: "#f0f6f7" }}
        >
          <img
            src={ArrowIcon}
            className={`arrow-icon ${setActive === "" ? "" : "rotate"}`}
            alt="arrow-logo"
          />
        </button>
      </div>
      <div
        ref={content}
        style={{ display: setActive === "active" ? "block" : `none` }}
        className="accordion__content"
      >
        {children}
      </div>
    </div>
  );
}
