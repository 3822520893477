import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { authReducer } from "../../features/auth/store";
import { companyUserReducer } from "../../features/company_users/redux/reducers/company_user_reducer";
import { langReducer } from "../../features/language/redux/reducers/lang_reducer";
import menuReducer from "./menuReducer";
import { datastreamReducer } from "../../features/datastream-config/store";

// ✅ Persist config
const persistConfig = {
  key: "appState", // Key for storage
  storage: storageSession, // Use sessionStorage
};

const datastreamPersistedReducer = persistReducer(
  persistConfig,
  datastreamReducer
);

export default combineReducers({
  authReducer: authReducer,
  companyUserReducer: companyUserReducer,
  langReducer: langReducer,
  menuReducer: menuReducer,
  datastream: datastreamPersistedReducer,
});
