import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { companyUserDetailsAction } from "../features/company_users/redux/actions/company_user_action";
import {
  getPathPrefix,
  loadSideBarJs,
  nameCapitalize,
} from "../utils/coreUtils";
import { USER_FORM_OPTION } from "../features/profile/UpdateUser";
import companyUserApi from "../services/apis/companyUserApi";
import { toast } from "react-toastify";
import { useMemo } from "react";
import GeneratePinOTPModal from "./Modals/generatePin/GeneratePinOTPModal";
import pinApi from "../services/apis/pinApi";
import ViewGeneratedPin from "./Modals/generatePin/ViewGeneratedPin";
import { useTranslation } from "react-i18next";
import ToastUtils from "../utils/ToastUtils";
import CacheUtils from "../utils/CacheUtils";

function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const userData = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(userData);
  const companyUserDetailsData = useSelector(
    (state) => state.companyUserReducer.companyUserDetailsData
  );

  const [showGeneratePin, setShowGeneratePin] = useState(false);
  const [companyPin, setCompanyPin] = useState("456789");
  const [showCompanyPin, setShowCompanyPin] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    await navigate(getPathPrefix() + "login");
    localStorage.clear();
    CacheUtils.removeAllCache();
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    const userId = localStorage.getItem("companyUserId");
    dispatch(companyUserDetailsAction(userId)).then((response) => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          userRoleType: response?.userRoleType,
          firstName: response?.firstName,
          lastName: response?.lastName,
          profilePicture: response?.profilePicture,
        })
      );
    });
  }, []);

  useEffect(() => {
    const sideBar = document.querySelectorAll("#sidebar .nav-parent");

    const clickEvent = () => {
      const body = document.getElementsByTagName("body")[0];
      if (
        !body.className.includes("sidebar-toggle-display") &&
        !body.className.includes("sidebar-absolute")
      ) {
        body.classList.value = "";
        localStorage.setItem("sideBar", "");
      }
    };

    if (sideBar) {
      sideBar.forEach((e) => {
        e.addEventListener("click", clickEvent);
      });
    }

    return () => {
      if (sideBar) {
        sideBar.forEach((e) => {
          e.removeEventListener("click", clickEvent);
        });
      }
    };
  }, []);

  useEffect(() => {
    loadSideBarJs();

    // toggle based on stored state of sidebar
    const body = document.getElementsByTagName("body")[0];
    if (
      !body.className.includes("sidebar-toggle-display") &&
      !body.className.includes("sidebar-absolute")
    ) {
      const sideBarState = localStorage.getItem("sideBar") ?? "sidebar-hidden";
      body.classList.value = sideBarState;
    }
  }, [location?.pathname]);

  const onToggle = () => {
    const body = document.getElementsByTagName("body")[0];
    if (
      body.className.includes("sidebar-toggle-display") ||
      body.className.includes("sidebar-absolute")
    ) {
      localStorage.setItem("sideBar", "sidebar-hidden");
      body.classList.toggle("sidebar-hidden");
    } else {
      localStorage.setItem(
        "sideBar",
        body.classList.contains("sidebar-icon-only") ? "" : "sidebar-icon-only"
      );
      body.classList.toggle("sidebar-icon-only");
    }
  };

  const sendOtpForGeneratePin = async () => {
    try {
      const response = await pinApi.generateAccessToken({
        companyId: localStorage.getItem("companyId"),
      });
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
      }
      setShowGeneratePin(true);
    } catch (error) {
      if (error.response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error.response?.data?.code}`));
      }
    }
  };

  // for changePass
  const handleChangePass = async () => {
    await companyUserApi.generateOtpChangePassword({}).then((res) => {
      // toast.success(res.data.message);
      if (res.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${res?.data?.code}`));
      }
      if (res.data.status === "200") {
        navigate(getPathPrefix() + `change-password-otp-page`);
      }
    });
  };

  const getRoleFullName = useMemo(() => {
    if (userDetails?.userRoleType === "RU") {
      return t("userRole.routingUser");
    } else if (userDetails?.userRoleType === "CU") {
      return t("userRole.companyUser");
    }
    return t("userRole.companyAdmin");
  }, [t, userDetails?.userRoleType]);

  return (
    <nav
      className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
      style={{
        background:
          "linear-gradient(90deg, rgb(1, 65, 76) 0%, rgb(61, 148, 159) 100%)",
        height: "70px",
      }}
    >
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo mr-5" href={getPathPrefix()}>
          <img
            src="/assets/images/Scandi-automation.png"
            className="mr-2"
            alt="logo"
          />
        </a>
        <a className="navbar-brand brand-logo-mini" href={getPathPrefix()}>
          <img src="/assets/images/Scandi-automation.png" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          id="minimize-btn"
          data-toggle="minimize"
          onClick={onToggle}
        >
          <span>
            <i className="fa-solid fa-bars-staggered"></i>
          </span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle p-0"
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <div className="d-flex user-box align-items-center">
                <div className="user-info">
                  <p className="user-name mb-0">
                    {nameCapitalize(companyUserDetailsData?.firstName)}{" "}
                    {nameCapitalize(companyUserDetailsData?.lastName)}
                  </p>
                  <p className="designattion mb-0">{getRoleFullName}</p>
                </div>
                <img
                  src={
                    companyUserDetailsData?.profilePicture ??
                    "/assets/images/user-icon.png"
                  }
                  className="user-img"
                  alt="user avatar"
                />
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              {companyUserDetailsData?.userRoleType === "CA" && (
                <div className="dropdown-item" onClick={sendOtpForGeneratePin}>
                  <i className="fa-sharp fa-solid fa-key"></i>
                  <a>{t("topHeader.viewPin")}</a>
                </div>
              )}
              <NavLink
                className="dropdown-item"
                to={getPathPrefix(
                  `company-user/create-user?t=${USER_FORM_OPTION.UPDATE_ADMIN}`
                )}
              >
                <i className="fa-solid fa-user"></i>
                {t("topHeader.updateProfile")}
              </NavLink>
              {/* <NavLink className="dropdown-item" to="change-old-password">
                <i className="fa-solid fa-lock"></i>Change password
              </NavLink> */}
              <a
                className="dropdown-item"
                onClick={() => {
                  handleChangePass();
                }}
              >
                <i className="fa-solid fa-lock"></i>
                {t("topHeader.changePassword")}
              </a>
              {/* <a onClick={handleEnglishClick} className="dropdown-item">
                <i className="fa fa-language"></i>Language
              </a> */}
              <a onClick={handleLogout} className="dropdown-item">
                <i className="fa-solid fa-right-from-bracket"></i>
                {t("topHeader.logout")}
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span>
            <i className="fa-solid fa-bars-staggered"></i>
          </span>
        </button>
      </div>

      <GeneratePinOTPModal
        asModal
        show={showGeneratePin}
        onSuccess={(pin) => {
          setCompanyPin(pin);
          setShowCompanyPin(true);
          setShowGeneratePin(false);
        }}
        onClose={() => {
          setShowGeneratePin(false);
          setCompanyPin(undefined);
        }}
      />
      <ViewGeneratedPin
        asModal
        show={showCompanyPin}
        companyPin={companyPin}
        onClose={() => {
          setShowCompanyPin(false);
          setCompanyPin(undefined);
        }}
      />
      {/*<ToastComponent /> */}
    </nav>
  );
}

export default NavBar;
