import AICCRuleBuilderUtils from "../utils/AICCRuleBuilderUtils";

// formatOP = ({ field, opVal, opDef, value })

const AICCBuilderInitialConfig = {
  operators: [
    // number operators
    {
      label: "Integer ==",
      def: "=",
      value: "number_equal",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer !=",
      def: "<>",
      value: "number_not_equal",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer Between",
      def: "",
      value: "number_between",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer Not Between",
      def: "",
      value: "number_not_between",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer <",
      def: "<",
      value: "number_less",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer <=",
      def: "<=",
      value: "number_less_equal",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer >",
      def: ">",
      value: "number_greater",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer >=",
      def: ">=",
      value: "number_greater_equal",
      type: "number",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer Any In",
      def: "IN",
      value: "number_in",
      type: "number",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
    },
    {
      label: "Integer Not Any In",
      def: "NOT IN",
      value: "number_not_in",
      type: "number",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
    },
    {
      label: "Integer Is Null",
      def: "IS",
      value: "number_is_null",
      type: "number",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Integer Is Not Null",
      def: "IS NOT",
      value: "number_is_not_null",
      type: "number",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },

    // float operators
    {
      label: "Decimal ==",
      def: "=",
      value: "decimal_equal",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal !=",
      def: "<>",
      value: "decimal_not_equal",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal Between",
      def: "",
      value: "decimal_between",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal Not Between",
      def: "",
      value: "decimal_not_between",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal <",
      def: "<",
      value: "decimal_less",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal <=",
      def: "<=",
      value: "decimal_less_equal",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal >",
      def: ">",
      value: "decimal_greater",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal >=",
      def: ">=",
      value: "decimal_greater_equal",
      type: "decimal",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal Any In",
      def: "IN",
      value: "decimal_in",
      type: "decimal",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
    },
    {
      label: "Decimal Not Any In",
      def: "NOT IN",
      value: "decimal_not_in",
      type: "decimal",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
    },
    {
      label: "Decimal Is Null",
      def: "IS",
      value: "decimal_is_null",
      type: "decimal",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Decimal Is Not Null",
      def: "IS NOT",
      value: "decimal_is_not_null",
      type: "decimal",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },

    // string operators
    {
      label: "String ==",
      def: "=",
      value: "text_equal",
      type: "text",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "String !=",
      def: "<>",
      value: "text_not_equal",
      type: "text",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "String Is Empty",
      def: "",
      value: "text_is_empty",
      type: "text",
      noInput: true,
      // opFormat: ({ field, opVal, opDef, value }) => {
      opFormat: ({ field, opVal }) => {
        if (!field || !opVal) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `COALESCE(${field}, '') = ''`;
      },
    },
    {
      label: "String Is Not Empty",
      def: "",
      value: "text_is_not_empty",
      type: "text",
      noInput: true,
      opFormat: ({ field, opVal }) => {
        if (!field || !opVal) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `COALESCE(${field}, '') <> ''`;
      },
    },
    {
      label: "String Any In",
      def: "IN",
      value: "text_in",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
      showCaseInsensitiveCheck: true,
    },
    {
      label: "String Not Any In",
      def: "NOT IN",
      value: "text_not_in",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field) return "";

        if (!opVal) return field;

        if (!value) return `${field} ${opDef}`;

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} (${value})`;
      },
      showCaseInsensitiveCheck: true,
    },
    {
      label: "String Starts With",
      def: "LIKE",
      value: "text_starts_with",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field || !opVal || !value) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} '${value}%' `;
      },
    },
    {
      label: "String Ends With",
      def: "LIKE",
      value: "text_ends_with",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field || !opVal || !value) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} '%${value}' `;
      },
    },
    {
      label: "String Contains",
      def: "LIKE",
      value: "text_contains",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field || !opVal || !value) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} '%${value}%' `;
      },
      showCaseInsensitiveCheck: true,
    },
    {
      label: "String Not Contains",
      def: "NOT LIKE",
      value: "text_not_contains",
      type: "text",
      opFormat: ({ field, opVal, opDef, value }) => {
        if (!field || !opVal || !value) return "";

        // const allNumbers = value.split(",").map(e => e.trim())

        return `${field} ${opDef} '%${value}%' `;
      },
      showCaseInsensitiveCheck: true,
    },
    {
      label: "String Is Null",
      def: "IS",
      value: "text_is_null",
      type: "text",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "String Is Not Null",
      def: "IS NOT",
      value: "text_is_not_null",
      type: "text",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },

    // boolean operators
    {
      label: "Boolean ==",
      def: "=",
      value: "boolean_equal",
      type: "boolean",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Boolean !=",
      def: "<>",
      value: "boolean_not_equal",
      type: "boolean",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Boolean Is Null",
      def: "IS",
      value: "boolean_is_null",
      type: "boolean",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "Boolean Is Not Null",
      def: "IS NOT",
      value: "boolean_is_not_null",
      type: "boolean",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },

    // date time operators
    {
      label: "DateTime ==",
      def: "=",
      value: "datetime_equal",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime !=",
      def: "<>",
      value: "datetime_not_equal",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime Between",
      def: "",
      value: "datetime_between",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime Not Between",
      def: "",
      value: "datetime_not_between",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime <",
      def: "<",
      value: "datetime_less",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime <=",
      def: "<=",
      value: "datetime_less_equal",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime >",
      def: ">",
      value: "datetime_greater",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime >=",
      def: ">=",
      value: "datetime_greater_equal",
      type: "datetime",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime Is Null",
      def: "IS",
      value: "datetime_is_null",
      type: "datetime",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
    {
      label: "DateTime Is Not Null",
      def: "IS NOT",
      value: "datetime_is_not_null",
      type: "datetime",
      noInput: true,
      directValue: "NULL",
      opFormat: AICCRuleBuilderUtils.opCommonFormat,
    },
  ],
  arithmeticOperators: [
    { label: "+", value: "+" },
    { label: "-", value: "-" },
    { label: "*", value: "*" },
    { label: "/", value: "/" },
    { label: "%", value: "%" },
  ],
  language: {
    // header
    addRuleButtonLabel: "Rule",
    addGroupButtonLabel: "Group",

    fieldStreamPlaceholder: "Select Field Stream",
    noFieldStreamOptions: "No field stream options",

    fieldPlaceholder: "Select Field",
    noFieldOptions: "No field options",

    arithmeticCheckboxTooltip: "Apply arithmetic operation?",
    arithmeticSelectPlaceholder: "Select Operator",
    arithmeticValuePlaceholder: "Enter number",

    operatorPlaceholder: "Select Operator",
    noOperatorOptions: "No operators",

    andCombinatorLabel: "AND",
    orCombinatorLabel: "OR",

    // value editor
    valueAnySelectPlaceholder: "Select options",
    valueAnySelectNoOptions: "No options",

    commaSeparatedInputPlaceholder: "Enter comma separated values",
    commaSeparatedInputNumberError:
      "Please enter valid comma separated numbers.",

    betweenAndLabel: "and",

    valueValueSourceOption: "Value",
    fieldValueSourceOption: "Field",

    numberBetweenMinPlaceholder: "Enter min",
    numberBetweenMaxPlaceholder: "Enter max",

    numberInputPlaceholder: "Enter number",

    stringInputPlaceholder: "Enter string",
  },
};

export { AICCBuilderInitialConfig };
