import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import Documents from "./Documents";
import GeographicalControl from "../features/selectedDataStream/geographical/GeographicalControl";
import NavBar from "./NavBar";
import Routing from "./Routing";
import RuleEngineVersions from "../features/datastream-config/screens/RuleEngineVersions";
import RuleEngineVersionForm from "../features/datastream-config/screens/RuleEngineVersionForm";
import RuleEngineRuleList from "../features/datastream-config/screens/RuleEngineRuleList";
import VersionRuleForm from "../features/datastream-config/screens/VersionRuleForm";
import SideBar from "./SideBar";
import AddGeographicalArea from "../features/selectedDataStream/geographical/AddGeographicalArea";
import FieldConfiguration from "./FieldConfiguration";
import AddField from "./AddField";
import AddUser from "./AddUser";
import UploadSchema from "./UploadSchema";
import ViewCompany from "./ViewCompany";
import ManageSubscription from "./ManageSubscription";
import ContactAicc from "./ContactAicc";
import UserListPage from "../features/company_users/pages/userList.page";
import UpdateUser from "../features/profile/UpdateUser";
// import UpdateUser from "../features/profile/update_user";
import ChangePassword from "../features/auth/pages/change_password/ChangePassword";
import EnterOldPassword from "../features/auth/pages/change_password/EnterOldPassword";
import VeirfyResetPasswordOtp from "../features/auth/pages/change_password/VeirfyResetPasswordOtp";
import { getPathPrefix } from "../utils/coreUtils";
import RoutingUserList from "../features/company_users/pages/RoutingUserList";
import FieldConfigJson from "../features/datastream/fieldConfig/FieldConfigJson";
import RulePriority from "../features/selectedDataStream/RulePriority";
import RulePrioritySelectable from "../features/selectedDataStream/RulePrioritySelectable";
import DataStreamProcess from "../features/datastream/DataStreamProcess";
import DataStreamReport from "../features/datastream/fieldConfig/DataStreamReport";
import CheckSelectedDataStream from "./CheckSelectedDataStream";

function MainIndex() {
  const token = localStorage.getItem("authToken");

  if (!token) {
    return <Navigate to={getPathPrefix("login")} replace />;
  }

  return (
    <div>
      <NavBar />
      <SideBar />
      <Routes>
        <Route path="/dashboard" element={<Index />} />
        <Route exact path="/document" element={<Documents />} />

        <Route element={<CheckSelectedDataStream />}>
          <Route
            exact
            path="selected-datastream/routing"
            element={<Routing />}
          />
          <Route
            path="/selected-datastream/rule-engine/versions"
            element={<RuleEngineVersions />}
          />
          <Route
            path="/selected-datastream/rule-engine/versions/create"
            element={<RuleEngineVersionForm />}
          />
          <Route
            path="/selected-datastream/rule-engine/versions/:versionId"
            element={<RuleEngineVersionForm />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule"
            element={<RuleEngineRuleList />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/add"
            element={<VersionRuleForm />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/:overviewId"
            element={<VersionRuleForm />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/priority"
            element={<RulePriority />}
          />
          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/priority-selectable"
            element={<RulePrioritySelectable />}
          />

          {/*for geographical*/}
          <Route
            exact
            path="/selected-datastream/geographical-control"
            element={<GeographicalControl />}
          />

          <Route
            exact
            path="/selected-datastream/geographical-control/create"
            element={<AddGeographicalArea />}
          />

          <Route
            exact
            path="/selected-datastream/geographical-control/:geographicId"
            element={<AddGeographicalArea />}
          />
          <Route
            exact
            path="/selected-datastream/report/:dataStreamConfigId"
            element={<DataStreamReport />}
          />
          <Route
            path="/selected-datastream/config-json/:dataStreamConfigId"
            element={<FieldConfigJson />}
          />
        </Route>

        <Route
          exact
          path="/datastream/field-config"
          element={<FieldConfiguration />}
        />
        <Route
          exact
          path="/datastream/processing"
          element={<DataStreamProcess />}
        />
        <Route path="/company-user/user-list" element={<UserListPage />} />
        <Route
          path="/company-user/routing-user-list"
          element={<RoutingUserList />}
        />
        <Route path="/company-user/create-user" element={<UpdateUser />} />
        <Route path="/company-user/update-user" element={<UpdateUser />} />
        <Route
          path="/company-user/view-user/:userId"
          element={<UpdateUser />}
        />
        <Route
          path="/company-user/update-user/:userId"
          element={<UpdateUser />}
        />
        {/* <Route exact path="/user-list" element={<UserList />} /> */}
        <Route path="/add-field" element={<AddField />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/upload-schema" element={<UploadSchema />} />
        <Route path="/view-company/view" element={<ViewCompany />} />
        <Route
          path="/view-company/subscription"
          element={<ManageSubscription />}
        />
        <Route path="/help-me/contact" element={<ContactAicc />} />
        {/* <Route
            exact
            path="/create-user"
            element={<CreateCompanyUserPage />}
          /> */}
        <Route path="/user-list" element={<UserListPage />} />
        <Route path="/change-old-password" element={<EnterOldPassword />} />
        <Route
          path="/change-password-otp-page"
          element={<VeirfyResetPasswordOtp />}
        />
        <Route path="/change-password-reset" element={<ChangePassword />} />
      </Routes>
    </div>
  );
}

export default MainIndex;
