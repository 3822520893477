import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import i18next from "i18next";
import { localizationLanguageAction } from "../features/language/redux/actions/lang_action";
import { ToastComponent } from "../library/components/toast.component";
import openApi from "../services/apis/openApi";
import pinApi from "../services/apis/pinApi";
import { getPathPrefix, isInitialPath } from "../utils/coreUtils";

export default function HandleRouting() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageError, setPageError] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const getValidPath = (currPath) => {
    const languageCode = localStorage.getItem("languageCode") ?? "en";
    const domainName = localStorage.getItem("companyDomain");

    if (params?.languageCode !== languageCode) {
      return currPath.replace(
        params?.companyDomain + "/" + params?.languageCode,
        domainName + "/" + languageCode
      );
    }

    return currPath;
  };

  const checkForNewUser = async () => {
    const companyDomain = localStorage.getItem("companyDomain");

    const currPath = location.pathname + location.search;

    /**
     * lt: Login type
     * cid: Company id
     * cp: Company pin
     *
     * If above params are exist in url then logout other and login again.
     */
    if (
      searchParams.has("lt") &&
      searchParams.has("cid") &&
      searchParams.has("cp")
    ) {
      if (searchParams.get("lt") === "admin") {
        try {
          const { data } = await pinApi.verifyPinAndGenerateAccessToken({
            companyId: searchParams.get("cid"),
            pin: searchParams.get("cp"),
          });

          const superAdminTokenRes = await openApi.generateAccessToken({
            companyId: data?.companyId,
          });

          localStorage.setItem("apt", superAdminTokenRes.data.token);

          localStorage.setItem("authToken", data?.jwttoken);
          localStorage.setItem("companyUserId", data?.companyUserId);
          localStorage.setItem("companyId", data?.companyId);
          localStorage.setItem(
            "companyName",
            superAdminTokenRes.data?.companyName
          );
          localStorage.removeItem("encKeyLink");

          navigate(getPathPrefix("dashboard"), {
            replace: true,
          });
          setIsLoading(false);
          return;
        } catch (error) {
          handleError(error);
          setIsLoading(false);
          return;
        }
      }
    }

    setIsLoading(false);

    if (!localStorage.getItem("authToken")) {
      if (
        window.location.href.indexOf("encyptedKey") > 0 ||
        window.location.href.indexOf("new-user") > 0
      ) {
        navigate(getPathPrefix("new-user"), {
          state: { encUrl: window.location.href },
          replace: true,
        });
      } else if (
        isInitialPath(location.pathname) ||
        currPath === "/" + companyDomain ||
        currPath === "/" + companyDomain + "/"
      ) {
        navigate(getPathPrefix("login"), {
          replace: true,
        });
      } else {
        navigate(getValidPath(currPath), {
          replace: true,
        });
      }
    } else {
      // TODO: comment last 2 condition in when dynamic domain integrated
      if (
        isInitialPath(location.pathname) ||
        currPath === "/" + companyDomain ||
        currPath === "/" + companyDomain + "/"
      ) {
        navigate(getPathPrefix("dashboard"), {
          replace: true,
        });
      } else {
        navigate(getValidPath(currPath), {
          replace: true,
        });
      }
    }
  };

  const handleError = (error) => {
    if (
      error.status === 400 ||
      error?.response?.status === 400 ||
      error?.response?.data?.status === -1
    ) {
      if (typeof error?.response?.data === "string") {
        setPageError(error?.response?.data);
      } else if (typeof error?.response?.data?.message === "string") {
        setPageError(error?.response?.data?.message);
      } else if (typeof error?.response?.data?.response === "string") {
        setPageError(error?.response?.data?.response);
      } else {
        setPageError("Facing issue from the server");
      }
    } else {
      // even if facing error, redirect user to the specific page.
      // getLanguageAndRedirect();
    }
  };

  const verifyDomain = async () => {
    const companyDetails = localStorage.getItem("domainInfo");

    if (companyDetails) {
      const response = await openApi.getCompanyAccessDetail(
        JSON.parse(companyDetails)
      );

      const langCode = localStorage.getItem("languageCode") ?? "en";

      if (
        response?.data?.companyLanguageCode &&
        langCode !== response?.data?.companyLanguageCode
      ) {
        localStorage.setItem(
          "languageCode",
          response?.data?.companyLanguageCode
        );
      }

      return response;
    }
  };

  const getLanguageAndRedirect = async () => {
    try {
      await verifyDomain();
    } catch (error) {
      handleError(error);
      setIsLoading(false);
      return;
    }

    const langCode = localStorage.getItem("languageCode") ?? "en";
    dispatch(localizationLanguageAction(langCode))
      .then((langData) => {
        i18next.addResourceBundle("other", "translation", langData);
        i18next.changeLanguage("other");
        checkForNewUser();
        // setIsLoading(false);
      })
      .catch(() => {
        // setIsLoading(false);
        checkForNewUser();
      });
  };

  const isDifferentDomain = () => {
    const companyDomain = localStorage.getItem("companyDomain");

    if (
      typeof companyDomain === "string" &&
      typeof params?.companyDomain === "string" &&
      companyDomain !== params?.companyDomain
    ) {
      return true;
    }

    return false;
  };

  const getAndUpdateCompanyDetails = async () => {
    try {
      localStorage.setItem("companyDomain", params?.companyDomain);

      const companyDetails = await openApi.getCompanyInformationByDomainName({
        domainName: params?.companyDomain,
      });

      localStorage.setItem("domainInfo", JSON.stringify(companyDetails.data));

      await verifyDomain();

      // store language that will be used later on in localStorage
      localStorage.setItem("languageCode", companyDetails.data?.languageCode);

      // redirect user to specific page.
      await getLanguageAndRedirect();
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const getCompanyDetails = async () => {
    // localStorage.setItem("languageCode", "en");

    setIsLoading(true);

    // if domain is different then refresh all and remove logged in data.
    if (isDifferentDomain()) {
      localStorage.clear();
      getAndUpdateCompanyDetails();
      return;
    } else {
      try {
        const domainInfo = localStorage.getItem("domainInfo");

        const companyDetails = await openApi.getCompanyInformationByDomainName({
          domainName: params?.companyDomain,
        });

        if (domainInfo && companyDetails.data?.companyId) {
          const parsedInfo = JSON.parse(domainInfo);

          if (parsedInfo.companyId !== companyDetails.data?.companyId) {
            localStorage.clear();
            getAndUpdateCompanyDetails();
          }
        }
      } catch (error) {
        handleError(error);
        setIsLoading(false);
      }
    }
    const langCode = localStorage.getItem("languageCode");

    if (langCode) {
      // Need to verify here that subscription is active or not.
      await getLanguageAndRedirect();
      return;
    }

    // Check if domain is passed or not in URL or not for example https://host/
    if (!params?.companyDomain) {
      // if not passed then check domain in localStorage
      const companyDomain = localStorage.getItem("companyDomain");

      // if there also domain is not available then show error
      if (!companyDomain) {
        setIsLoading(false);
        setPageError(
          "You're on invalid page. Please use URL provided to you for login purpose."
        );
      } else {
        // else redirect user to the specific page.
        await getLanguageAndRedirect();
      }
      return;
    }

    getAndUpdateCompanyDetails();
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  // Need to show full screen loading while getting token from the admin
  return (
    <>
      {isLoading ? (
        <div
          className="row text-center align-items-center m-0 fw-bold"
          style={{
            height: "100vh",
          }}
        >
          <span>Loading...</span>
        </div>
      ) : pageError ? (
        <div
          className="row text-center align-items-center m-0 text-danger fw-bold"
          style={{
            height: "100vh",
          }}
        >
          <div>
            <h1 style={{ fontWeight: "bolder" }}>ERROR</h1>
            {pageError && <p className="mb-0">{pageError}</p>}
            <p>Contact Scandi Automation admin for support.</p>
          </div>
        </div>
      ) : (
        <Outlet />
      )}

      <ToastComponent />
    </>
  );
}
