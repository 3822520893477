import { forwardRef } from "react";
import { createRef, useImperativeHandle } from "react";
import ReactSelect from "react-select";

const AICCRuleOperator = forwardRef(
  (
    { operatorList = [], value, onChange, noOptionsLabel, placeholder },
    ref
  ) => {
    const selectRef = createRef();

    const styles = {
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%",
      }),
    };

    useImperativeHandle(
      ref,
      () => ({
        clearSelect: () => {
          if (selectRef?.current) {
            selectRef.current.clearValue();
          }
        },
      }),
      [selectRef]
    );

    return (
      <div className="form-group mb-0">
        <ReactSelect
          ref={selectRef}
          styles={styles}
          value={operatorList.find((e) => e.value === value)}
          classNamePrefix={"select"}
          placeholder={placeholder ?? "Select Operator"}
          noOptionsMessage={() => noOptionsLabel}
          options={operatorList}
          onChange={onChange}
          menuPosition={"fixed"}
        />
      </div>
    );
  }
);

AICCRuleOperator.displayName = "AICCRuleOperator";

export default AICCRuleOperator;
