import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomModal from "../../../components/Modals/CustomModal";
import { getPathPrefix } from "../../../utils/coreUtils";

const RuleEngineActions = ({
  classes = "",
  editPath,
  canEdit = true,
  viewPath,
  deleteMessage,
  deleteId,
  deleteHandler,
  canDelete,
  additonalActions = [],
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  return (
    <>
      <div className={`row gap-2 justify-content-center ${classes}`}>
        <OverlayTrigger
          placement="auto"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {t("versionsForm.tooltip.edit")}
            </Tooltip>
          )}
        >
          <span
            className="cursor-pointer action-icon"
            onClick={() => {
              if (canEdit) {
                navigate(getPathPrefix(editPath));
              }
            }}
            style={{ opacity: !canEdit ? 0.5 : "" }}
          >
            <i className="fa-regular fa-edit"></i>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="auto"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {t("versionsForm.tooltip.view")}
            </Tooltip>
          )}
        >
          <span
            className="cursor-pointer action-icon"
            onClick={() => {
              navigate(getPathPrefix(viewPath));
            }}
          >
            <i className="fa-regular fa-eye"></i>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="auto"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {t("versionsForm.tooltip.delete")}
            </Tooltip>
          )}
        >
          <span className="action-icon">
            <button
              className="border-0 bg-transparent"
              onClick={() => {
                if (canDelete) {
                  setShowDelete(true);
                }
              }}
              disabled={!canDelete}
              style={{ opacity: !canDelete ? 0.5 : "" }}
            >
              <i className="fa-regular fa-trash-can"></i>
            </button>
          </span>
        </OverlayTrigger>
        {additonalActions.map((action) => action)}
      </div>
      <CustomModal
        show={showDelete}
        centered
        noHeader
        body={
          <h5
            className="modal-title text-center w-100"
            style={{ color: "#004e5c" }}
          >
            {deleteMessage}
          </h5>
        }
        okTitle={t("CommonKeys.yes")}
        cancelTitle={t("CommonKeys.no")}
        centerFooter
        onOk={() => {
          deleteHandler(deleteId);
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
      />
    </>
  );
};

export default RuleEngineActions;
