import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import AiccBreadCrumb from "../../components/common/AiccBreadCrumb";
import CenterLoading from "../../components/common/CenterLoading";
import DangerButton from "../../components/Form/Buttons/DangerButton";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import ConfirmActivateModal from "../../components/Modals/ConfirmActivateModal";
import rulesApi from "../../services/apis/rulesApi";
import { getPathPrefix, nameCapitalize, reorder } from "../../utils/coreUtils";
import ToastUtils from "../../utils/ToastUtils";

// const getItems = (count) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `item ${k}`,
//   }));

export default function RulePrioritySelectable() {
  const params = useParams();
  const navigate = useNavigate();

  // const [list, setList] = useState(getItems(100));
  const [leavingWithoutSave, setLeavingWithoutSave] = useState(false);
  const [ruleOverviewList, setRuleOverviewList] = useState([]);
  const [originalList, setOriginalList] = useState("[]");
  const [currentItem, setCurrentItem] = useState();
  // const [showOrderModal, setShowOrderModal] = useState(false);
  const [order, setOrder] = useState("");
  const [inputError, setInputError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const streamObj = useSelector((state) => state?.datastream?.selected);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      ruleOverviewList,
      result.source.index,
      result.destination.index
    );

    setRuleOverviewList(items);
  }

  const onChangeOrder = (e) => {
    if (parseInt(order) <= 0 || parseInt(order) > ruleOverviewList.length) {
      setInputError(
        `Input must be greater than 0 and less then ${ruleOverviewList.length}`
      );
      return;
    }

    if (e) e.preventDefault();

    onDragEnd({
      destination: {
        index: parseInt(order) - 1,
      },
      source: {
        index: currentItem.index - 1,
      },
    });
    const obj = document.getElementById(currentItem.item.rulesId);
    setTimeout(() => {
      console.log(obj);
      if (obj) {
        obj.scrollIntoView({
          block: "center",
        });
      }
    }, 200);
    setCurrentItem(undefined);
    setOrder("");
    setInputError("");
  };

  async function getAllRouteUserByDataStreamConfigId() {
    setIsLoading(true);
    try {
      const response = await rulesApi.getAllRulesByDSConfigVersionId({
        dsConfigVersionId: params?.versionId,
      });

      const finalList = (response?.data ?? [])
        .sort(function (a, b) {
          return a.priority - b.priority;
        })
        .filter((e) => e.isActive && e.priority !== -1);

      setRuleOverviewList(finalList);
      setOriginalList(JSON.stringify(finalList));
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const savePriority = async () => {
    try {
      const response = await rulesApi.updatePriorityForRules(
        ruleOverviewList.map((e, index) => ({
          rulesId: e.rulesId,
          priority: index + 1,
        }))
      );

      if (response.data.status == 200) {
        toast.success(t(`statusCodes.${response.data.code}`));
        navigate(-1);
      } else if (response.data.code) {
        ToastUtils.serverError(t(`statusCodes.${response.data.code}`));
      }

      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRouteUserByDataStreamConfigId();
  }, [params?.versionId]);

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper p-0">
          <div className="main-panel">
            <div className="content-wrapper">
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={streamObj?.customDataStreamName}
                items={t("alterRulePriority.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              <ConfirmActivateModal
                show={leavingWithoutSave}
                onOk={() => {
                  // onSubmitHandler(true);
                  savePriority();
                  setLeavingWithoutSave(false);
                }}
                onClose={() => {
                  navigate(-1);
                  setLeavingWithoutSave(false);
                }}
                messageTitles={t("alterRulePriority.leaveWithoutSaving")}
              />

              {/* <RuleOrderModal
              currOrder={currentItem}
              show={showOrderModal}
              inputMin={1}
              inputMax={list.length}
              onClose={() => {
                setShowOrderModal(false);
              }}
              onOkay={(currOrder, order) => {
                onDragEnd({
                  destination: {
                    index: parseInt(order),
                  },
                  source: {
                    index: currOrder.index,
                  },
                });
                setCurrentItem(undefined);
                setShowOrderModal(false);
              }}
            /> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <div className="card-label">
                          <img
                            src="/assets/images/engine.svg"
                            alt=""
                            className="me-2"
                          />
                          {t("alterRulePriority.cardTitle")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="bg-white d-flex pb-3 pt-1 px-2 rounded-3 shadow sticky-top"
                      style={{ top: "80px" }}
                    >
                      <Form.Group className="col-auto flex-fill me-3">
                        <Form.Label>
                          {t("alterRulePriority.orderNumber")}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={1}
                          max={ruleOverviewList.length}
                          disabled={!currentItem}
                          value={"" + order}
                          autoFocus
                          placeholder={t(
                            "alterRulePriority.orderNumberPlaceholder"
                          )}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              onChangeOrder(e);
                            }
                          }}
                          onChange={(e) => {
                            if (
                              parseInt(e.target.value) >= 1 &&
                              parseInt(e.target.value) <=
                                ruleOverviewList.length
                            ) {
                              if (inputError) {
                                setInputError("");
                              }
                            } else {
                              if (!inputError) {
                                setInputError(
                                  `Input must be greater then 0 and less then or equal ${ruleOverviewList.length}`
                                );
                              }
                            }
                            setOrder(e.target.value);
                          }}
                        />
                        {inputError && (
                          <h6 className="text-danger mt-1 input-error-text">
                            {inputError}
                          </h6>
                        )}
                      </Form.Group>

                      <div className="col-auto" style={{ marginTop: "30px" }}>
                        <PrimaryButton
                          disabled={
                            !currentItem ||
                            currentItem?.index === parseInt(order) ||
                            inputError
                          }
                          title={t("alterRulePriority.changeOrder")}
                          onClick={() => {
                            onChangeOrder();
                          }}
                        />
                      </div>
                    </div>

                    {isLoading ? (
                      <CenterLoading />
                    ) : (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(mainProvided, mainSnapshot) => (
                            <div
                              {...mainProvided.droppableProps}
                              ref={mainProvided.innerRef}
                            >
                              <div
                                className="row pt-4 "
                                style={{
                                  marginLeft: "78px",
                                }}
                              >
                                <span
                                  className="col-auto fw-bold"
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  {t("alterRulePriority.ruleName")}
                                </span>
                                {/* <span className="col fw-bold text-center">
                                  Current Priority
                                </span> */}
                                <span
                                  className="col-auto fw-bold text-center"
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  {t("alterRulePriority.oldPriority")}
                                </span>
                                <span
                                  className="col-auto fw-bold text-center me-4"
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  {t("alterRulePriority.ruleType")}
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-auto">
                                  {ruleOverviewList.map((item, index) => (
                                    <span
                                      key={`rule-overview-${index}`}
                                      className="px-3 d-block text-center fw-bold"
                                      style={{
                                        marginTop: "12px",
                                      }}
                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>
                                <div className="col">
                                  {ruleOverviewList.map((item, index) => (
                                    <Draggable
                                      key={"" + item.rulesId}
                                      draggableId={"" + item.rulesId}
                                      index={index}
                                      isDragDisabled={Boolean(
                                        currentItem?.item?.id
                                      )}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            id={"" + item.rulesId}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...provided.draggableProps.style,
                                              backgroundColor:
                                                currentItem?.item?.rulesId ===
                                                  item.rulesId ||
                                                snapshot.isDragging
                                                  ? "#004e5c"
                                                  : "unset",
                                              color:
                                                currentItem?.item?.rulesId ===
                                                  item.rulesId ||
                                                snapshot.isDragging
                                                  ? "white"
                                                  : "black",
                                              opacity:
                                                mainSnapshot.isDraggingOver
                                                  ? snapshot.isDragging
                                                    ? 1
                                                    : 0.5
                                                  : 1,
                                              boxShadow: "2px 2px 10px #d4d4d4",
                                              borderRadius: "8px",
                                              marginTop: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <div className="row px-3">
                                              <div
                                                className="d-flex col-auto"
                                                style={{
                                                  width: "50%",
                                                }}
                                              >
                                                <Form>
                                                  <Form.Check
                                                    inline
                                                    checked={
                                                      currentItem?.item
                                                        ?.rulesId ===
                                                      item.rulesId
                                                    }
                                                    onChange={(e) => {
                                                      if (!e.target.checked) {
                                                        setCurrentItem(
                                                          undefined
                                                        );
                                                        setOrder("");
                                                      } else {
                                                        setCurrentItem({
                                                          index: index + 1,
                                                          item,
                                                        });
                                                        setOrder(index + 1);
                                                      }
                                                    }}
                                                  />
                                                </Form>
                                                <OverlayTrigger
                                                  placement="auto"
                                                  // delay={{ show: 250, hide: 400 }}
                                                  overlay={(props) => (
                                                    <Tooltip
                                                      id="button-tooltip"
                                                      {...props}
                                                    >
                                                      {nameCapitalize(
                                                        item.name
                                                      )}
                                                    </Tooltip>
                                                  )}
                                                >
                                                  <span className="text-ellipsis text-start">
                                                    {nameCapitalize(item.name)}
                                                  </span>
                                                </OverlayTrigger>
                                              </div>

                                              {/* <span className="col justify-content-center text-center">
                                                {index + 1}
                                              </span> */}
                                              <span
                                                className="col-auto justify-content-center text-center"
                                                style={{
                                                  width: "20%",
                                                }}
                                              >
                                                {item.priority}
                                              </span>
                                              <span
                                                className="col-auto justify-content-center text-center"
                                                style={{
                                                  width: "20%",
                                                }}
                                              >
                                                {item.ruleTypeInfo?.label}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  ))}
                                </div>
                              </div>
                              {mainProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}

                    <div
                      className="bg-white d-flex justify-content-end mt-3 p-2 rounded-3 shadow sticky-top"
                      style={{ bottom: "5px" }}
                    >
                      <PrimaryButton
                        title={t("CommonKeys.saveButtonTitle")}
                        icon="fa-regular fa-floppy-disk"
                        disabled={
                          originalList === JSON.stringify(ruleOverviewList)
                        }
                        onClick={() => {
                          savePriority();
                        }}
                      />
                      <DangerButton
                        title={t("CommonKeys.cancelButtonTitle")}
                        className="ms-3"
                        icon={"fa-solid fa-xmark"}
                        onClick={() => {
                          if (
                            originalList !== JSON.stringify(ruleOverviewList)
                          ) {
                            setLeavingWithoutSave(true);
                          } else {
                            navigate(-1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
