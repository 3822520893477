import { InputGroup } from "react-bootstrap";

const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  noPad,
  disabled,
  leftText,
  // leftIcon,
  rightIcon,
  rightText,
  initValidation,
  ...props
}) => (
  <div className={["form-group", noPad ? "mb-0" : ""].join(" ")}>
    <label className="mb-2 form-label">
      {label}
      {required && (
        <sup>
          <i
            className="fa-solid fa-star-of-life"
            style={{
              color: "red",
              fontSize: "5px",
              marginBottom: "5px",
            }}
          ></i>
        </sup>
      )}
    </label>
    <InputGroup>
      {rightIcon && <InputGroup.Text>{rightIcon}</InputGroup.Text>}
      {rightText && <InputGroup.Text>{rightText}</InputGroup.Text>}
      <input
        type="text"
        {...field}
        {...{ ...props, required }}
        disabled={isSubmitting || disabled}
        className="form-control"
      />
      {leftText && <InputGroup.Text>{leftText}</InputGroup.Text>}
    </InputGroup>
    {(initValidation
      ? errors[field.name]
      : touched[field.name] && errors[field.name]) && (
      <h6 className="text-danger mt-1 input-error-text">
        {errors[field.name]}
      </h6>
    )}
  </div>
);

export default TextInput;
