const PrimaryButton = ({
  disabled,
  className = "",
  title,
  icon,
  type = "button",
  onClick,
  style,
  iconStyle,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      style={style}
      className={["btn btn-green w-auto", className].join(" ")}
    >
      {icon && <i className={["me-2", icon].join(" ")} style={iconStyle}></i>}
      {title}
    </button>
  );
};

export default PrimaryButton;
