import axios from "axios";
import i18next from "i18next";
import { ADMIN_BASE_URL, BASE_URL } from "../constants";
import ToastUtils from "../utils/ToastUtils";

const instance = axios.create();

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      throw error;
    }
    if (
      typeof error?.config?.headers?.showToast === "boolean" &&
      !error?.config?.headers?.showToast
    )
      throw error;

    if (error?.response?.status >= 400 && error?.config?.method !== "get") {
      if (typeof error?.response?.data === "string") {
        ToastUtils.serverError(error?.response?.data);
      } else if (typeof error?.response?.data?.code === "string") {
        ToastUtils.serverError(
          i18next.t(`statusCodes.${error?.response?.data?.code}`)
        );
      } else if (typeof error?.response?.data?.message === "string") {
        ToastUtils.serverError(error?.response?.data?.message);
      } else if (typeof error?.response?.data?.response === "string") {
        ToastUtils.serverError(error?.response?.data?.response);
      } else {
        ToastUtils.serverError();
      }
    }
    throw error;
  }
);

export const REQUEST_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const REQUEST_CONTENT_TYPE = {
  JSON: "application/json",
  MULTIPART: "multipart/form-data",
};

const defaultReqDetails = {
  isAdmin: false,
  useAdminAuth: false,
  showToast: undefined,
  method: REQUEST_METHODS.GET,
  contentType: REQUEST_CONTENT_TYPE.JSON,
  body: {},
};

export const doFetch = (url, reqDetails = defaultReqDetails) => {
  const {
    isAdmin = defaultReqDetails.isAdmin,
    method = defaultReqDetails.method,
    body = defaultReqDetails.body,
    useAdminAuth = defaultReqDetails.useAdminAuth,
    showToast,
    ...otherOptions
  } = reqDetails;

  const { contentType = defaultReqDetails.contentType, signal } =
    otherOptions ?? {};

  let finalBaseUrl = isAdmin ? ADMIN_BASE_URL : BASE_URL;

  if (finalBaseUrl[finalBaseUrl.length - 1] === "/") {
    finalBaseUrl = finalBaseUrl.substring(0, finalBaseUrl.length - 1);
  }

  let options = {
    // url: `${process.env.REACT_APP_BASE_URL_ADMIN}${url}`,
    url: `${finalBaseUrl}${url}`,
    method,
    headers: {
      "Content-Type": contentType,
    },
  };

  const token = localStorage.getItem("authToken");
  if (token && !useAdminAuth) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  const apt = localStorage.getItem("apt");
  if (useAdminAuth) {
    options.headers.Authaccesstoken = `Accesstoken ${apt}`;
  }

  if (showToast !== undefined) {
    options.headers.showToast = showToast;
  }

  // signal to cancel request
  if (signal) {
    options.signal = signal;
  }

  if (contentType && contentType.includes("json")) {
    options.data = JSON.stringify(body);
  } else {
    options.data = body;
  }

  return instance(options);
};
