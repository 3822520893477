export default function RequiredDot() {
  return (
    <sup>
      <i
        className="fa-solid fa-star-of-life"
        style={{
          color: "red",
          fontSize: "5px",
          marginBottom: "5px",
        }}
      ></i>
    </sup>
  );
}
