import { Draggable } from "react-beautiful-dnd";
import { Accordion } from "./Accordian";
import { Droppable } from "react-beautiful-dnd";

var mapIndex = 0;
var indexMap = {};

const getIndex = (path) => {
  let avl = null;
  Object.keys(indexMap).forEach((key) => {
    let value = indexMap[key];
    if (value === path) {
      avl = key;
    }
  });
  if (avl) {
    return Number.parseInt(avl);
  } else {
    mapIndex = mapIndex + 1;
    indexMap[mapIndex] = path;
    return mapIndex;
  }
};

export const ListMaker = (item, handleClick, dragStart) => {
  return (
    <>
      {item?.fields?.length === 0 ? (
        <a /* onClick={() => handleClick(item)} */>
          <Draggable
            key={item.path}
            draggableId={item.path}
            index={getIndex(item.path)}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <p className="schema-name">{item.path}</p>
              </div>
            )}
          </Draggable>
        </a>
      ) : (
        <Accordion
          title={item?.path?.length > 0 ? item?.path : item?.name}
          // onClick={() => handleClick(item, "parent")}
        >
          {item?.fields?.map((childItem) => {
            return ListMaker(childItem, handleClick, dragStart);
          })}
        </Accordion>
      )}
    </>
  );
};
export const GroupMaker = (
  items,
  handleClick,
  handleItemClick,
  deleteClick
) => {
  return (
    <>
      {items?.map((item, index) => {
        return (
          <Accordion
            key={`group-maker-${index}`}
            title={item?.name}
            onClick={() => {
              if (item.order !== -1) {
                handleClick(item);
              }
            }}
          >
            <Droppable
              droppableId={"group-drop-area|" + item?.name}
              isDropDisabled={item.order === -1}
            >
              {/* <Droppable droppableId={"droppable"}> */}
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {item?.children?.map((childItem, childIndex) => {
                    // console.log(childItem.isDeleted);
                    return (
                      <Draggable
                        key={`${childItem.pathName}-${childIndex}`}
                        draggableId={"group-" + childItem.pathName}
                        index={getIndex(item?.name)}
                        isDragDisabled
                      >
                        {(provided) => (
                          <div className="col-md-width">
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p className="schema-name form-row-space d-flex align-items-center justify-content-between">
                                <a
                                  className={
                                    childItem.isDeleted == true
                                      ? "show-deleted"
                                      : undefined
                                  }
                                  onClick={() => {
                                    handleItemClick(childItem);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {childItem.fieldName}{" "}
                                  {childItem.isPrimarySecondary
                                    ? " - " +
                                      (childItem.isPrimarySecondary ===
                                      "primary"
                                        ? "Primary Key"
                                        : "Secondary Key")
                                    : ""}
                                </a>
                                {(childItem.createdType === undefined ||
                                  childItem.createdType !== "SUPER_ADMIN") && (
                                  <button
                                    type="button"
                                    className="close-btn"
                                    onClick={() => {
                                      deleteClick(
                                        childItem,
                                        childItem?.isDeleted
                                          ? "undo"
                                          : undefined
                                      );
                                    }}
                                  >
                                    {!childItem?.isDeleted ? (
                                      <i className="fa fa-xmark"></i>
                                    ) : (
                                      <svg
                                        className=""
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="UndoIcon"
                                        style={{
                                          height: "18px",
                                          width: "18px",
                                          marginRight: "-3px",
                                        }}
                                      >
                                        <path
                                          d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"
                                          fill="#a3a8a9"
                                        ></path>
                                      </svg>
                                    )}
                                  </button>
                                )}
                                {/* <IconButton
                                    size="small"
                                    edge="start"
                                    color="inherit"
                                    aria-label="close"
                                      onClick={
                                    () => {
                                      deleteClick(childItem)
                                    }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton> */}
                              </p>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </Accordion>
        );
      })}
    </>
  );
};
