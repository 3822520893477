import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../components/common/CustomToggle";

const AICCValueSource = ({ value, language, onChange }) => {
  const changeMyValue = (val) => {
    if (onChange) {
      onChange(val);
    }
  };
  return (
    <Dropdown style={{ marginTop: "6px" }}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <i className="fa-solid fa-ellipsis-vertical px-2"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <Dropdown.Item
          eventKey="1"
          active={!value || value === "value"}
          onClick={() => {
            changeMyValue("value");
          }}
        >
          {language?.valueValueSourceOption ?? "Value"}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          active={value === "field"}
          onClick={() => {
            changeMyValue("field");
          }}
        >
          {language?.valueValueSourceOption ?? "Field"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AICCValueSource;
