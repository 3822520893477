import companyUserTypes from "../actionTypes";
import { types } from "../../../auth/store";

const companyUserListStore = {
  companyUserListData: [],
  companyUserSaveOrUpdateData: {},
  companyDeleteUserIdData: {},
  companyUserDetailsData: {},
};

export function companyUserReducer(state = companyUserListStore, action) {
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case companyUserTypes.COMPANY_USER_LIST:
      return {
        ...state,
        companyUserListData: action.payload.companyUserListData,
      };
    case companyUserTypes.SAVE_OR_UPDATE_COMPANY:
      return {
        ...state,
        companyUserSaveOrUpdateData: action.payload.companyUserSaveOrUpdateData,
      };
    case companyUserTypes.DELETE_COMPANY_USERID:
      return {
        ...state,
        companyDeleteUserIdData: action.payload.companyDeleteUserIdData,
      };
    case companyUserTypes.COMPANY_USER_DETAILS:
      return {
        ...state,
        companyUserDetailsData: action.payload.companyUserDetailsData,
      };
    default:
      return { ...state };
  }
}
