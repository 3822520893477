import { doFetch, REQUEST_METHODS } from "../fetcher";

export default {
  authenticate: (body) =>
    doFetch("/authenticate", {
      body,
      method: REQUEST_METHODS.POST,
    }),
  loginOtpVerification: (body) =>
    doFetch(`/authenticate/otpVerificationForAuthenticationToken`, {
      body: body,
      method: REQUEST_METHODS.POST,
    }),
  otpVerificationForNewUser: (body) =>
    doFetch(`/companyNewUser/otpVerificationForNewUser`, {
      body: body,
      method: REQUEST_METHODS.POST,
    }),
};
