import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_CONTENT_TYPE, REQUEST_METHODS } from "../fetcher";

export default {
  generateAccessToken: (query) =>
    doFetch(`/pin/generateOtpForPin${jsonToQuery(query)}`),
  generatePin: (query) => doFetch(`/pin/generatePin${jsonToQuery(query)}`),
  verifyPinAndGenerateAccessToken: (data) =>
    doFetch(`/pin/verifyPinAndGenrateAccessToken${jsonToQuery(data)}`, {
      contentType: REQUEST_CONTENT_TYPE.JSON,
      method: REQUEST_METHODS.POST,
      body: data,
    }),
};
