import { createSlice } from "@reduxjs/toolkit";

const datastreamState = { selected: null };

const datastreamSlice = createSlice({
  name: "datastream",
  initialState: datastreamState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setSelected } = datastreamSlice.actions;
export default datastreamSlice.reducer;
