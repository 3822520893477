import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  CRUD_GEOGRAPHICAL_TYPES,
  formatDateToMyFormat,
  getPathPrefix,
  nameCapitalize,
} from "../../../utils/coreUtils";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";
import CenterLoading from "../../../components/common/CenterLoading";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import geographicalAPi from "../../../services/apis/geographicalAPi";
import CustomModal from "../../../components/Modals/CustomModal";
import ToastUtils from "../../../utils/ToastUtils";
import "../styles.scss";

// const tempObj = {
//   geographicalAreaversion: "version",
//   geographicalCreatedBy: "danny",
//   geographicalCreatedDate: moment(new Date()),
//   geographicalUpdatedBy: "rahul",
//   geographicalUpdatedDate: moment(new Date()),
// };
const GeographicalControl = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const streamObj = useSelector((state) => state?.datastream?.selected);
  const [geographicalData, setGeographicalData] = useState([]);
  const [resetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [geographicalToDelete, setGeographicalToDelete] = useState();

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
        color: "#004e5c",
        fontWeight: 800,
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
      },
    },
  };

  useEffect(() => {
    getAllGeographicalAreaControl();
  }, []);

  async function getAllGeographicalAreaControl() {
    setIsLoading(true);
    try {
      const response =
        await geographicalAPi.getAllGeographicalAreaControlByDSConfigId({
          dsConfigId: streamObj?.dataStreamConfigId,
        });
      setGeographicalData(response?.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const columns = useMemo(
    () => [
      {
        name: t("geographical.columns.version"),
        selector: (row) => nameCapitalize(row.geographicalAreaName),
        sortable: true,
        minWidth: "200px",
        className: "",
      },
      {
        name: t("geographical.columns.createdBy"),
        // selector: (row) => nameCapitalize(row.createdBy),
        selector: (row) =>
          row.createdBy === null ? "N/A" : nameCapitalize(row.createdBy),
        sortable: true,
        minWidth: "125px",
      },
      {
        name: t("geographical.columns.createdDate"),
        selector: (row) => formatDateToMyFormat(row.createdAt, "DD/MM/YYYY"),
        sortable: true,
        minWidth: "150px",
      },
      {
        name: t("geographical.columns.updatedBy"),
        selector: (row) =>
          // row.updatedBy === null ? "N/A" : nameCapitalize(row.updatedBy),
          row.updatedBy === null
            ? nameCapitalize(row.createdBy)
            : nameCapitalize(row.updatedBy),
        sortable: true,
        minWidth: "130px",
      },
      {
        name: t("geographical.columns.updatedDate"),
        selector: (row) =>
          row.updatedBy === null
            ? formatDateToMyFormat(row.createdAt, "DD/MM/YYYY")
            : formatDateToMyFormat(row.updatedAt, "DD/MM/YYYY"),
        sortable: true,
        minWidth: "150px",
      },
      {
        name: t("geographical.columns.status"),
        cell: (row) => (
          <div className="switch mt-1">
            <input
              type="checkbox"
              id="status"
              name="status"
              checked={row.isActive}
              onChange={(e) => {
                subStatusHandler(row, e.target.checked);
                selectedRow.row = row;
                selectedRow.checked = e.target.checked;
                setSelectedRow({ ...selectedRow });
              }}
            />
          </div>
        ),
        minWidth: "50px",
      },
      {
        name: t("geographical.columns.actions"),
        center: true,
        minWidth: "170px",
        cell: (row) => (
          <div className="row gap-2 justify-content-center">
            {/* <span
              className="w-auto cursor-pointer action-icon"
              // onClick={() => {
              //   alert(row.geographicalAreaControlId);
              // }}
            >
              <i className="fa-regular fa-copy"></i>
            </span> */}
            <OverlayTrigger
              placement="auto"
              // delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {t("versionsForm.tooltip.edit")}
                </Tooltip>
              )}
            >
              <span
                className="col-3 cursor-pointer action-icon"
                onClick={() => {
                  navigate(
                    getPathPrefix(
                      `selected-datastream/geographical-control/${row.geographicalAreaControlId}?t=${CRUD_GEOGRAPHICAL_TYPES.EDIT}`
                    )
                  );
                }}
              >
                <i className="fa-regular fa-edit"></i>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="auto"
              // delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {t("versionsForm.tooltip.edit")}
                </Tooltip>
              )}
            >
              <span
                className="col-3 cursor-pointer action-icon"
                onClick={() => {
                  navigate(
                    getPathPrefix(
                      `selected-datastream/geographical-control/${row.geographicalAreaControlId}?t=${CRUD_GEOGRAPHICAL_TYPES.VIEW}`
                    )
                  );
                }}
              >
                <i className="fa-regular fa-eye"></i>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="auto"
              // delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {t("versionsForm.tooltip.edit")}
                </Tooltip>
              )}
            >
              <span className="col-3 action-icon">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    setShowDelete(true);
                    setGeographicalToDelete(row);
                  }}
                >
                  <i className="fa-regular fa-trash-can"></i>
                </a>
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
    ],
    []
  );

  // for checkboxes
  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check form-check-sm form-check-custom">
          <input
            type="checkbox"
            className="form-check-input"
            // style={{ height: "20px", width: "20px" }}
            ref={ref}
            onClick={onClick}
            {...rest}
          />
        </div>
      </>
    );
  });

  Checkbox.displayName = "Checkbox";

  const deleteHandler = async (id) => {
    try {
      const response = await geographicalAPi.deleteGeographicalAreaControlById({
        geographicalAreaControlId: id,
      });
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
      }
      getAllGeographicalAreaControl();
    } catch (error) {
      /* empty */
    }
  };

  const subStatusHandler = async (row, isChecked) => {
    const createRequest = {
      geographicalAreaControlId: row.geographicalAreaControlId,
      isActive: isChecked,
      isDeleted: false,
    };

    try {
      const response =
        await geographicalAPi.saveOrUpdateGeographicalAreaControl(
          createRequest
        );
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
        getAllGeographicalAreaControl();
      }
    } catch (error) {
      //  if (error.response.data.isDSConfigVersionActiveAlready) {
      //    setAlreadyActive(error.response.data.isDSConfigVersionActiveAlready);
      //  }
    }
  };

  return (
    <>
      <CustomModal
        show={showDelete}
        centered
        noHeader
        body={
          <h5
            className="modal-title text-center w-100"
            style={{ color: "#004e5c" }}
          >
            {t("geographical.delete")}{" "}
            <b>{geographicalToDelete?.geographicalAreaName}</b>{" "}
            {t("geographical.columns.delete")} ?
          </h5>
        }
        okTitle={t("CommonKeys.yes")}
        cancelTitle={t("CommonKeys.no")}
        centerFooter
        onOk={() => {
          deleteHandler(geographicalToDelete?.geographicalAreaControlId);
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
      />
      <div className="container-scroller">
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div className="main-panel list-geographical">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={streamObj?.customDataStreamName}
                items={t("geographical.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <div className="card-label">
                          <img
                            src="/assets/images/globe.svg"
                            alt=""
                            className="me-2"
                          />{" "}
                          {t("geographical.geographicalControl")}
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <div className="d-block d-md-flex justify-content-end">
                          <PrimaryButton
                            title={t("geographical.addGeographicalControl")}
                            icon="fa-regular fa-square-plus"
                            className="me-2"
                            onClick={() => {
                              navigate(
                                getPathPrefix() +
                                  "selected-datastream/geographical-control/create/"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive">
                    <DataTable
                      responsive
                      persistTableHead
                      keyField={"id"}
                      progressPending={isLoading}
                      progressComponent={<CenterLoading minHeight={"150px"} />}
                      columns={columns}
                      data={geographicalData}
                      paginationResetDefaultPage={resetPaginationToggle}
                      pagination
                      // className="table table-modern"
                      // selectableRows
                      striped
                      // selectableRowsSingle
                      // selectableRowSelected={(row) =>
                      //   selectedRow?.geographicalAreaControlId ===
                      //     row.geographicalAreaControlId &&
                      //   selectedRow?.dsConfigId === row.dsConfigId
                      // }
                      // selectableRowsComponent={Checkbox} // Pass the Checkbox component only
                      // onSelectedRowsChange={(e) => {
                      //   if (e.selectedCount <= 0) setSelectedRow(undefined);

                      //   setSelectedRow(e.selectedRows[0]);
                      // }}
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </>
  );
};

export default GeographicalControl;
