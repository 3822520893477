import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CenterLoading from "../../../components/common/CenterLoading";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import ConfirmActivateModal from "../../../components/Modals/ConfirmActivateModal";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";
import RuleEngineActions from "../components/RuleEngineActions";
import {
  CRUD_RULE_ENGINE_TYPES,
  formatDateToMyFormat,
  getPathPrefix,
  nameCapitalize,
} from "../../../utils/coreUtils";
import ruleEngine from "../../../services/apis/ruleEngine";
import ToastUtils from "../../../utils/ToastUtils";
import "../styles/styles.scss";

const RuleEngineVersions = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const streamObj = useSelector((state) => state?.datastream?.selected);
  // const [delFile, SetDelFile] = useState("");
  // const [selFile, SetSelFile] = useState("");
  // const [data, setData] = useState([]);
  const [searchRule, setSearchRule] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [ruleEngineData, setRuleEngineData] = useState([]);
  const [alreadyActive, setAlreadyActive] = useState(false);
  const [versionInfo, setGetVersionInfo] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [defaultPage, setDefaultPage] = useState();

  useEffect(() => {
    getDsConfigVersions();
    setDefaultPage(parseInt(sessionStorage.getItem("versionPage") ?? "1"));
  }, []);

  async function getDsConfigVersions() {
    setIsLoading(true);
    try {
      const response = await ruleEngine.getAllDSConfigVersionByDSConfigId({
        dsConfigId: streamObj.dataStreamConfigId,
      });
      setRuleEngineData(response?.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
        color: "#004e5c",
        fontWeight: 800,
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: t("versionsForm.columns.Version"),
        selector: (row) => {
          return (
            <OverlayTrigger
              placement="auto"
              // delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {nameCapitalize(row.versionName)}
                </Tooltip>
              )}
            >
              <a
                className="version-name-text active"
                onClick={() => {
                  navigate(
                    getPathPrefix(
                      `selected-datastream/rule-engine/versions/${row.dsConfigVersionId}/rule?a=${row.isActive}`
                    )
                  );
                }}
              >
                {nameCapitalize(row.versionName)}
              </a>
            </OverlayTrigger>
          );
        },
        minWidth: "100px",
        className: "",
      },
      {
        name: t("versionsForm.columns.createdBy"),
        selector: (row) => nameCapitalize(row.createdBy),
        sortable: true,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("versionsForm.columns.createdDate"),
        selector: (row) => formatDateToMyFormat(row.createdAt, "DD/MM/YYYY"),
        sortable: true,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("versionsForm.columns.updatedBy"),
        selector: (row) =>
          // formatDateToMyFormat(row.updatedBy, "DD/MM/YYYY"),
          !(row.updatedBy ?? row.createdBy)
            ? "N/A"
            : nameCapitalize(row.updatedBy ?? row.createdBy),
        minWidth: "180px",
        center: true,
      },
      {
        name: t("versionsForm.columns.changeDate"),
        selector: (row) =>
          !(row.updatedAt ?? row.createdAt)
            ? "N/A"
            : formatDateToMyFormat(
                row.updatedAt ?? row.createdAt,
                "DD/MM/YYYY"
              ),
        sortable: true,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("versionsForm.columns.status"),
        // selector: (row) => row.isActive ? 'Yes' : 'No',
        cell: (row) => (
          <div className="switch mt-1">
            <input
              type="checkbox"
              id="status"
              name="status"
              checked={row.isActive}
              onChange={(e) => {
                subStatusHandler(row, e.target.checked);
              }}
            />
          </div>
        ),
        minWidth: "70px",
        center: true,
      },
      {
        name: t("versionsForm.columns.actions"),
        center: true,
        minWidth: "170px",
        cell: (row) => (
          <RuleEngineActions
            editPath={`selected-datastream/rule-engine/versions/${row.dsConfigVersionId}?t=${CRUD_RULE_ENGINE_TYPES.EDIT}`}
            viewPath={`selected-datastream/rule-engine/versions/${row.dsConfigVersionId}?t=${CRUD_RULE_ENGINE_TYPES.VIEW}`}
            deleteMessage={
              <>
                {t("versionsForm.delete")} <b>{row?.versionName}</b>{" "}
                {t("versionsForm.version")}
              </>
            }
            deleteId={row.dsConfigVersionId}
            deleteHandler={deleteHandler}
            canDelete={!row.isActive}
          />
        ),
      },
    ],
    []
  );

  const searchdata = useMemo(() => {
    const foundedData = ruleEngineData?.filter((data) => {
      return searchRule.trim()
        ? data?.versionName.toLowerCase().includes(searchRule.toLowerCase())
        : true;
    });
    return foundedData;
  }, [ruleEngineData, searchRule]);
  // to search among the rules

  const searchRuleEngine = useMemo(() => {
    return (
      <>
        <div className="form-group col" style={{ paddingRight: "8px" }}>
          <input
            className="form-control"
            placeholder={t("versionsForm.searchVersion")}
            value={searchRule}
            onChange={(e) => {
              setSearchRule(e.target.value);
              if (e.target.value === "") {
                setResetPaginationToggle(!resetPaginationToggle);
              }
            }}
          />
        </div>
        <div className="col d-flex justify-content-end align-item-end mb-3 clear-btn-pad"></div>
      </>
    );
  }, [resetPaginationToggle, searchRule, t]);

  const subStatusHandler = async (row, isChecked, isOverrite) => {
    setGetVersionInfo(row);
    let createRequest = {
      dsConfigVersionId: row.dsConfigVersionId,
      companyId: parseInt(localStorage.getItem("companyId")),
      isActive: isChecked,
      dsConfigId: streamObj?.dataStreamConfigId,
      isDeleted: false,
    };
    if (typeof isOverrite === "boolean" && isOverrite) {
      createRequest["isOverrite"] = isOverrite;
    }

    try {
      const response =
        await ruleEngine.saveOrUpdateDSConfigVersion(createRequest);
      // if (response.status === 200) {
      //   toast.success(t("versionsForm.errorMessage.ruleStatusUpdated"));
      //   getDsConfigVersions();
      // } else {
      //   toast.error(t("versionsForm.errorMessage.ruleStatusFailure"));
      // }
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
        getDsConfigVersions();
      }
    } catch (error) {
      if (error.response.data.isDSConfigVersionActiveAlready) {
        setAlreadyActive(error.response.data.isDSConfigVersionActiveAlready);
      }
    }
  };

  const deleteHandler = async (id) => {
    try {
      const response = await ruleEngine.deleteDSConfigVersionById({
        dsConfigVersionId: id,
      });
      // if (response.status === 200) {
      //   toast.success(t("versionsForm.errorMessage.ruleDeletedSuccess"));
      // } else {
      //   toast.error(t("versionsForm.errorMessage.ruleDeletedFailure"));
      // }
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
      }
      getDsConfigVersions();
    } catch (error) {
      /* empty */
    }
  };

  // for checkboxes
  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check form-check-sm form-check-custom">
          <input
            type="checkbox"
            className="form-check-input"
            // style={{ height: "20px", width: "20px" }}
            ref={ref}
            onClick={onClick}
            {...rest}
          />
        </div>
      </>
    );
  });

  Checkbox.displayName = "Checkbox";

  const handleCreateCopy = async () => {
    // let createRequest = {
    //   dsConfigVersionId: 0,
    //   versionName: "copy - " + selectedRow.versionName,
    //   isActive: false,
    //   companyId: parseInt(localStorage.getItem("companyId")),
    //   dsConfigId: streamObj?.dataStreamConfigId,
    //   isDeleted: false,
    // };

    // if (typeof selectedRow.isOverrite === "boolean" && selectedRow.isOverrite) {
    //   createRequest["isOverrite"] = selectedRow.isOverrite;
    // }

    try {
      const response = await ruleEngine.copyDsConfigVersionIdWithRules({
        dsConfigVersionId: selectedRow.dsConfigVersionId,
      });
      if (response.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        getDsConfigVersions();
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
        getDsConfigVersions();
      }
    } catch (error) {
      if (error?.response?.data?.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error?.response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${error?.response?.data?.code}`));
        getDsConfigVersions();
      }

      if (error.response.data.isDSConfigVersionActiveAlready) {
        setAlreadyActive(error.response.data.isDSConfigVersionActiveAlready);
      } else if (error.response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error.response.data.code}`));
      }
    }
  };

  return (
    <div>
      <ConfirmActivateModal
        show={alreadyActive}
        onOk={() => {
          subStatusHandler(versionInfo, true, true);
          setAlreadyActive(false);
        }}
        onClose={() => {
          setAlreadyActive(false);
        }}
        messageTitles={t("versionsForm.modalErrorMessage")}
      />
      <div className="container-scroller list-rule-engin">
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={streamObj?.customDataStreamName}
                items={t("ruleEngineList.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-3">
                        <div className="card-label">
                          <img
                            src="/assets/images/engine.svg"
                            alt=""
                            className="me-2"
                          />{" "}
                          {t("versionsForm.ruleEngine")}
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <div className="d-block d-md-flex justify-content-end">
                          {/* <PrimaryButton
                            title={t("versionsForm.addRuleEngine")}
                            icon="fa-solid fa-plus"
                            className="me-2"
                            onClick={() => {
                              navigate(getPathPrefix("ruleengine-operation"));
                            }}
                          /> */}
                          <PrimaryButton
                            title={t("versionsForm.createActiveVersion")}
                            icon="fa-regular fa-square-plus"
                            className="me-2"
                            onClick={() => {
                              navigate(
                                getPathPrefix() +
                                  "selected-datastream/rule-engine/versions/create/"
                              );
                            }}
                          />
                          <PrimaryButton
                            title={t("versionsForm.createCopy")}
                            // icon="fa-sharp fa-solid fa-rotate"
                            icon="fa-sharp fa-solid fa-copy"
                            className="me-2"
                            disabled={selectedRow ? false : true}
                            onClick={() => {
                              handleCreateCopy();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive">
                    {/* <div className="data-table"> */}
                    <DataTable
                      responsive
                      persistTableHead
                      keyField={"id"}
                      progressPending={isLoading}
                      progressComponent={<CenterLoading minHeight={"150px"} />}
                      columns={columns}
                      data={searchdata}
                      paginationResetDefaultPage={resetPaginationToggle}
                      pagination
                      className="table table-modern"
                      selectableRows
                      striped
                      selectableRowsSingle
                      selectableRowSelected={(row) =>
                        selectedRow?.dsConfigVersionId ===
                          row.dsConfigVersionId &&
                        selectedRow?.dsConfigId === row.dsConfigId
                      }
                      paginationDefaultPage={defaultPage}
                      onChangePage={(page) => {
                        sessionStorage.setItem("versionPage", page);
                      }}
                      selectableRowsComponent={Checkbox} // Pass the Checkbox component only
                      onSelectedRowsChange={(e) => {
                        if (e.selectedCount <= 0) setSelectedRow(undefined);

                        setSelectedRow(e.selectedRows[0]);
                      }}
                      customStyles={customStyles}
                      subHeader
                      subHeaderAlign="left"
                      subHeaderWrap={true}
                      subHeaderComponent={searchRuleEngine}
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
      {/* <!-- container-scroller --> */}
    </div>
  );
};

export default RuleEngineVersions;
