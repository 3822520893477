export const AUTH_ENDPOINT = {
  LOGIN: "authenticate",
  CHANGE_PASSWORD: "companyUser/changePassword",
  CHANGE_PASSWORD_OTP: "companyUser/generateOtpForChangePassword",
  FORGET_PASSWORD: "companyNewUser/generateOtpForForgotPassword",
  SET_PASSWORD_FOR_FORGOT_PASSWORD:
    "companyNewUser/setPasswordForForgotPassword",
  OTP_VERIFICATION_FOR_FORGOT_PASSWORD:
    "companyNewUser/otpVerificationForForgotPassword",
  OTP_VERIFICATION_FOR_CHANGE_PASSWORD:
    "companyUser/otpVerificationForchangePassword",
  NEW_USER_OTP: "companyNewUser/generateOtpForNewUser",
  SET_PASSWORD_FOR_NEW_USER: "companyNewUser/setPasswordForNewUser",
};
