import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import moment from "moment";
import LeftPanel from "../components/leftPanel.component";
import {
  changeForgetPasswordAction,
  newUserOtpAction,
  otpVerificationForForgotPasswordAction,
} from "../store/auth.action";
import { getPathPrefix } from "../../../utils/coreUtils";
import authApi from "../../../services/apis/authApi";
import openApi from "../../../services/apis/openApi";
import StorageManager from "../../../utils/storageManager";
import { OTP_TIME } from "../../../constants";
import ToastUtils from "../../../utils/ToastUtils";
import { companyUserDetailsAction } from "../../company_users/redux/actions/company_user_action";
import "./Otp.css";

export const OTP_SCREEN_TYPE = {
  LOGIN: "login",
};

const OtpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [error, setError] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const [verificationOtp, setVerificationOtp] = useState("");

  // for resend Otp
  const minutes = 0;
  const seconds = 45;
  const [[mins, secs], setTime] = React.useState([minutes, seconds]);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const locationState = location.state;

  const currDate = useMemo(
    () => moment(StorageManager.get("otpSentTime") ?? moment().toISOString()),
    [isSendingOtp]
  );

  const changeForgetPasswordMailData = useSelector(
    (state) => state.authReducer.changeForgetPasswordMailData
  );
  const [t] = useTranslation();
  const [alreadyActivated, setAlreadyActivated] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // if user came to reset temporary password then send OTP to the registered email and phone
      if (location.state?.encUrl) {
        // console.log(location.state?.encUrl)
        const uncURL = location.state?.encUrl.split("encyptedKey=");
        const encKey = uncURL[1];

        localStorage.setItem("encKeyLink", encKey);

        dispatch(newUserOtpAction(encKey))
          .then(() => {
            StorageManager.set("otpSentTime", moment().toISOString());
          })
          .catch((err) => {
            // if data has isValid = false then show error that already activated
            if (err?.data?.message) {
              setError(err?.data?.message);
            } else if (!err.data.isValid) {
              setAlreadyActivated(true);
            }
          });
      }
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // for resend Opt
  const tick = () => {
    if (secs > 0) {
      const time = moment().diff(currDate, "seconds");

      setTime([mins, OTP_TIME - time]);
      /* if (currDate) {
      } else {
        setTime([mins, secs - 1]);
      } */
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [secs, mins]);

  const reset = () => setTime([parseInt(minutes), parseInt(seconds)]);

  const resendOtp = async () => {
    setIsSendingOtp(true);
    try {
      setVerificationOtp("");
      if (location.state?.encUrl) {
        // console.log(location.state?.encUrl);
        const uncURL = location.state?.encUrl.split("encyptedKey=");
        const encKey = uncURL[1];
        await dispatch(newUserOtpAction(encKey))
          .then(() => {
            StorageManager.set("otpSentTime", moment().toISOString());
          })
          .catch((err) => {
            // if data has isValid = false then show error that already activated
            if (err?.data?.message) {
              setError(err?.data?.message);
            } else if (!err.data.isValid) {
              setAlreadyActivated(true);
            }
          });
      } else if (location?.state?.type === OTP_SCREEN_TYPE.LOGIN) {
        const companyDetails = JSON.parse(
          localStorage.getItem("domainInfo") ?? "{}"
        );

        const res = await authApi.authenticate({
          password: locationState.password,
          username: locationState.username,
          companyId: companyDetails?.companyId,
        });
        StorageManager.set("otpSentTime", moment().toISOString());
        if (res.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
        }
      } else {
        await dispatch(
          changeForgetPasswordAction(locationState.forgotPasswordState, "CA106")
        )
          .then((res) => {
            // navigate(getPathPrefix("otp"));
            StorageManager.set("otpSentTime", moment().toISOString());
            if (res.data.status != 200) {
              ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
            } else {
              toast.success(t(`statusCodes.${res?.data?.code}`));
            }
          })
          .catch((e) => {
            console.log(e.data);
            if (typeof e.data === "string") {
              toast.error(e.data);
            } else {
              toast.error(e.data?.message);
            }
          });
      }
      reset();
    } catch (error) {
      console.error(error);
    }
    setIsSendingOtp(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDisable()) {
      setDisableButton(true);
      // if came from reset temporary password
      if (location.state?.encUrl) {
        try {
          const uncURL = location.state?.encUrl.split("encyptedKey=");

          const encKey = uncURL[1];
          const companyDetails = JSON.parse(
            localStorage.getItem("domainInfo") ?? "{}"
          );
          const response = await authApi.otpVerificationForNewUser({
            encryptedKey: encKey,
            otp: verificationOtp,
            companyId: companyDetails?.companyId,
          });
          if (response.data.status && response.data.status != 200) {
            ToastUtils.serverError(t(`statusCodes.${response.data?.code}`));
            return;
          }
          toast.success(t(`statusCodes.OTP_MATCHED`));
          navigate(getPathPrefix() + "reset-password", {
            state: {
              otpStates: verificationOtp,
            },
            // encUrlLink:localStorage.getItem('encKeyLink')
          });
        } catch (error) {
          /* empty */
        }
      } else if (location?.state?.type === OTP_SCREEN_TYPE.LOGIN) {
        // verification of otp while company login
        try {
          const companyDetails = JSON.parse(
            localStorage.getItem("domainInfo") ?? "{}"
          );

          const { data } = await authApi.loginOtpVerification({
            username: location.state?.username,
            password: location.state?.password,
            otp: verificationOtp,
            companyId: companyDetails?.companyId,
          });

          if (data.status && data.status != 200) {
            ToastUtils.serverError(t(`statusCodes.${data?.code}`));
            return;
          }

          const superAdminTokenRes = await openApi.generateAccessToken({
            companyId: data?.companyId,
          });

          localStorage.setItem("apt", superAdminTokenRes.data.token);

          localStorage.setItem("authToken", data?.jwttoken);
          localStorage.setItem("companyUserId", data?.companyUserId);
          localStorage.setItem("companyId", data?.companyId);
          localStorage.setItem(
            "companyName",
            superAdminTokenRes.data?.companyName
          );
          localStorage.removeItem("encKeyLink");

          dispatch(companyUserDetailsAction(data?.companyUserId));

          if (superAdminTokenRes.status != 200) {
            ToastUtils.serverError(
              t(`statusCodes.${superAdminTokenRes.data?.code}`)
            );
          } else {
            toast.success(t(`statusCodes.${superAdminTokenRes?.data?.code}`));
          }

          navigate(getPathPrefix() + `dashboard`);
        } catch (error) {
          /* empty */
        }
      } else {
        console.log(
          "changeForgetPasswordMailData",
          changeForgetPasswordMailData
        );
        // if came from forgot password
        await dispatch(
          otpVerificationForForgotPasswordAction(
            changeForgetPasswordMailData,
            verificationOtp
          )
        )
          .then((res) => {
            navigate(getPathPrefix("reset-password"), {
              state: {
                otpStates: verificationOtp,
              },
            });
            if (res.status != 200) {
              ToastUtils.serverError(t(`statusCodes.${res?.code}`));
            } else {
              toast.success(t(`statusCodes.${res?.code}`));
            }
          })
          .catch((e) => {
            // if (typeof e.data === "string") {
            //   toast.error(e.data);
            // } else {
            //   toast.error(e.data.message);
            // }
            if (e.data.status != 200) {
              ToastUtils.serverError(t(`statusCodes.${e?.data?.code}`));
            }
          });
      }
    } else {
      toast.error("Enter Otp");
    }

    setDisableButton(false);
  };

  const isDisable = () => {
    return verificationOtp.length < 6 || disableButton;
  };

  const showOtpPage = !alreadyActivated && !error;

  return (
    <>
      <section className="page main-signin-wrapper container-layout align-items-center otp-page">
        <div className="row signpages bg-app-primary">
          <div className="col-md-12">
            <div className="row">
              <LeftPanel />
              <div className="col-lg-6 d-flex flex-column right-panel">
                <div className="form-container d-flex flex-column">
                  <img
                    src={"/assets/images/scandi-name-automation.png"}
                    className="header-brand-img text-start mb-4 mx-auto"
                    alt="logo"
                  />
                  {/* {showOtpPage && (
                    <RightPanelHeader
                      title={t("language.check_email")}
                      subTitle={t("language.otp_content")}
                    />
                  )} */}
                  <form className="d-flex flex-column flex-fill justify-content-center">
                    {showOtpPage ? (
                      <>
                        <h5
                          className="text-primary-app mb-2 text-center"
                          style={{ fontWeight: "600" }}
                        >
                          {t("otp.check_email")}
                        </h5>
                        <h6
                          className="text-primary-app mb-4 text-center"
                          style={{ color: "#004e5c", fontSize: "13px" }}
                        >
                          {t("otp.otp_content")}
                        </h6>
                        <OtpInput
                          containerStyle={{
                            display: "flex",
                            justifyContent: "space-between",
                            maxWidth: "310px",
                          }}
                          value={verificationOtp}
                          onChange={setVerificationOtp}
                          numInputs={6}
                          separator={<span> </span>}
                          className="input-style mb-5 mx-auto"
                          shouldAutoFocus
                        />
                        <div className="form-group text-center">
                          <button
                            className="btn btn-fill text-uppercase"
                            disabled={isDisable()}
                            style={
                              isDisable()
                                ? { opacity: 0.4, cursor: "not-allowed" }
                                : {}
                            }
                            onClick={handleSubmit}
                          >
                            {t("CommonKeys.submitButtonTitle")}
                          </button>
                        </div>
                      </>
                    ) : (
                      <h5 className="text-center">
                        {error ??
                          "Facing some failures from server. Please try again later."}
                      </h5>
                    )}
                    <div className="form-group text-center">
                      {`${secs.toString().padStart(2)}` > 0 ? (
                        <b style={{ color: "#004e5c" }}>
                          {mins.toString().padStart(2, "0")} :{" "}
                          {secs.toString().padStart(2, "0")}
                        </b>
                      ) : isSendingOtp ? (
                        <b className="cursor-pointer">
                          {t("CommonKeys.sendingText")}
                        </b>
                      ) : (
                        <b
                          className="cursor-pointer"
                          onClick={() => {
                            resendOtp();
                          }}
                        >
                          {t("CommonKeys.resendOtpText")}
                        </b>
                      )}
                    </div>
                    <div className="form-group text-center">
                      <NavLink to={getPathPrefix("login")} className="">
                        {t("CommonKeys.backTo")}{" "}
                        <b>{t("CommonKeys.login_hyphen")}</b>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<ToastComponent /> */}
      </section>
    </>
  );
};

export default OtpPage;
