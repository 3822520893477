import { types } from "./auth.action";

const authStore = {
  loginData: {},
  newUserOtpData: {},
  companyUserIdData: {},
  changePasswordData: {},
  changePasswordOtpData: {},
  changeForgetPasswordData: {},
  setPasswordForNewUserData: {},
  changeForgetPasswordMailData: {},
  setPasswordForForgotPasswordData: {},
  otpVerificationForForgotPasswordData: {},
  otpVerificationForChangePasswordData: {},
  isLoading: false,
};

export default function authReducer(state = authStore, action) {
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case types.LOGIN:
      return {
        ...state,
        loginData: action.payload.loginData,
        companyUserIdData: action.payload.companyUserIdData,
      };
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordData: action.payload.changePasswordData,
      };
    case types.CHANGE_PASSWORD_OTP:
      return {
        ...state,
        changePasswordOtpData: action.payload.changePasswordOtpData,
      };
    case types.FORGET_PASSWORD:
      return {
        ...state,
        changeForgetPasswordData: action.payload.changeForgetPasswordData,
        changeForgetPasswordMailData:
          action.payload.changeForgetPasswordMailData,
      };
    case types.SET_PASSWORD_FOR_FORGOT_PASSWORD:
      return {
        ...state,
        setPasswordForForgotPasswordData:
          action.payload.setPasswordForForgotPasswordData,
      };
    case types.OTP_VERIFICATION_FOR_FORGOT_PASSWORD:
      return {
        ...state,
        otpVerificationForForgotPasswordData:
          action.payload.otpVerificationForForgotPasswordData,
      };
    case types.OTP_VERIFICATION_FOR_CHANGE_PASSWORD:
      return {
        ...state,
        otpVerificationForChangePasswordData:
          action.payload.otpVerificationForChangePasswordData,
      };
    case types.NEW_USER_OTP:
      return {
        ...state,
        newUserOtpData: action.payload.newUserOtpData,
      };
    case types.SET_PASSWORD_FOR_NEW_USER:
      return {
        ...state,
        setPasswordForNewUserData: action.payload.setPasswordForNewUserData,
      };
    default:
      return { ...state };
  }
}
