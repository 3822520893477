import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CenterLoading from "../../../components/common/CenterLoading";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import TextInput from "../../../components/Form/Inputs/TextInput";
import {
  CRUD_RULE_ENGINE_TYPES,
  getPathPrefix,
} from "../../../utils/coreUtils";
import ruleEngine from "../../../services/apis/ruleEngine";
import ConfirmActivateModal from "../../../components/Modals/ConfirmActivateModal";
import ToastUtils from "../../../utils/ToastUtils";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";

const RuleEngineVersionForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [versionsDetails, setVersionsDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [alreadyActive, setAlreadyActive] = useState(false);
  const formRef = useRef();
  const type = searchParams.get("t");
  const [t] = useTranslation();

  const streamObj = useSelector((state) => state?.datastream?.selected);

  const breadCrumbTitle = useMemo(() => {
    if (type === CRUD_RULE_ENGINE_TYPES.CREATE) {
      return t("versionsForm.createTitle");
    }
    if (type === CRUD_RULE_ENGINE_TYPES.EDIT) {
      return t("versionsForm.updateTitle");
    }
    if (type === CRUD_RULE_ENGINE_TYPES.VIEW) {
      return t("versionsForm.viewTitle");
    }

    return t("versionsForm.createTitle");
  }, [searchParams]);

  const formValidationSchema = Yup.object({
    versionName: Yup.string()
      .label(t("versionsForm.versionForm.versionName"))
      .required(),
    isActive: Yup.string().label(t("versionsForm.versionForm.versionStatus")),
  });

  const initialFormValues = useMemo(
    () => ({
      versionName: versionsDetails?.versionName ?? "",
      isActive: versionsDetails?.isActive ?? false,
    }),
    [versionsDetails]
  );

  const getVersionsDetails = async () => {
    setIsLoading(true);
    try {
      const response = await ruleEngine.getDSConfigVersionById({
        dsConfigVersionId: params?.versionId,
      });
      setVersionsDetails(response?.data);
    } catch (error) {
      /* empty */
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (params?.versionId) {
      getVersionsDetails();
    } else {
      setVersionsDetails(undefined);
    }
  }, [params?.versionId]);

  const onSubmit = async (values, isOverrite) => {
    let createRequest = {
      dsConfigVersionId:
        type === CRUD_RULE_ENGINE_TYPES.EDIT ? params?.versionId : 0,
      versionName: formRef.current.values.versionName,
      isActive: formRef.current.values.isActive,
      isDeleted: false,
      companyId: parseInt(localStorage.getItem("companyId")),
      dsConfigId: streamObj?.dataStreamConfigId,
    };

    if (typeof isOverrite === "boolean" && isOverrite) {
      createRequest["isOverrite"] = isOverrite;
    }

    try {
      const response =
        await ruleEngine.saveOrUpdateDSConfigVersion(createRequest);
      if (response.status === 200) {
        // if (type === CRUD_RULE_ENGINE_TYPES.EDIT) {
        //   toast.success(t("versionsForm.errorMessage.ruleUpdateSuccess"));
        // } else {
        //   toast.success(t("versionsForm.errorMessage.ruleSaveSuccess"));
        // }
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
        navigate(getPathPrefix() + "selected-datastream/rule-engine/versions/");
      } else {
        toast.error(t("versionsForm.errorMessage.ruleSaveFailure"));
      }
    } catch (error) {
      if (error.response.data.isDSConfigVersionActiveAlready) {
        setAlreadyActive(error.response.data.isDSConfigVersionActiveAlready);
      } else if (error.response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error.response.data.code}`));
      }
    }
  };

  return (
    <div>
      <ConfirmActivateModal
        show={alreadyActive}
        onOk={() => {
          onSubmit(undefined, true);
          setAlreadyActive(false);
        }}
        onClose={() => {
          setAlreadyActive(false);
        }}
        messageTitles={t("versionsForm.modalErrorMessage")}
      />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper p-0">
          <div className="main-panel">
            <div className="content-wrapper">
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={streamObj?.customDataStreamName}
                items={t("createVersion.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              {/* <div className="col-12 col-lg-9 col-md-12 mx-auto"> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="card-label mb-0 d-flex">
                          <i className="fa-solid fa-user-group me-3 fs-5 text-primary"></i>{" "}
                          {breadCrumbTitle} - {streamObj?.customDataStreamName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading ? (
                      <CenterLoading minHeight="100px" />
                    ) : (
                      <Formik
                        innerRef={formRef}
                        initialValues={initialFormValues}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                        validateOnMount={true}
                        validateOnBlur
                      >
                        {({
                          // values,
                          // errors,
                          // touched,
                          // handleChange,
                          // handleBlur,
                          handleSubmit,
                          isValid,
                          isSubmitting,
                          // setFieldValue,
                          dirty,
                          // ...props
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-6">
                                  <Field
                                    component={TextInput}
                                    label={t(
                                      "versionsForm.versionForm.versionName"
                                    )}
                                    placeholder={t(
                                      "versionsForm.versionForm.versionName"
                                    )}
                                    id="versionName"
                                    name="versionName"
                                    required
                                    disabled={
                                      type === CRUD_RULE_ENGINE_TYPES.VIEW
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <div className="switch">
                                    <label className="mb-2 form-label">
                                      Status
                                    </label>
                                    <Field
                                      type="checkbox"
                                      label={t(
                                        "versionsForm.versionForm.versionStatus"
                                      )}
                                      id="isActive"
                                      name="isActive"
                                      disabled={
                                        type === CRUD_RULE_ENGINE_TYPES.VIEW
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="text-end mt-4">
                                {type === CRUD_RULE_ENGINE_TYPES.VIEW ? (
                                  <PrimaryButton
                                    title={t("CommonKeys.backButtonTitle")}
                                    className="ms-2"
                                    icon="fa-solid fa-arrow-left me-2"
                                    onClick={async () => {
                                      navigate(
                                        getPathPrefix() +
                                          "selected-datastream/rule-engine/versions/"
                                      );
                                    }}
                                  />
                                ) : (
                                  <>
                                    <PrimaryButton
                                      type="submit"
                                      title={t("CommonKeys.saveButtonTitle")}
                                      icon={"fa-regular fa-floppy-disk"}
                                      disabled={
                                        !isValid || isSubmitting || !dirty
                                      }
                                    />
                                    <PrimaryButton
                                      title={t("CommonKeys.cancelButtonTitle")}
                                      className="ms-2"
                                      onClick={async () => {
                                        // navigate(`/subscription-plan/list/`);
                                        navigate(
                                          getPathPrefix() +
                                            "selected-datastream/rule-engine/versions/"
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleEngineVersionForm;
