import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_METHODS } from "../fetcher";

export default {
  saveOrUpdateDSConfigVersion: (data) =>
    doFetch(
      "/dsConfigVersion/saveOrUpdateDSConfigVersion",
      {
        method: REQUEST_METHODS.POST,
        body: data,
        showToast: false,
      }
      //   {
      //     isDeleted: false,
      //     ...data,
      //   }
    ),
  copyDsConfigVersionIdWithRules: (query) =>
    doFetch(
      `/dsConfigVersion/copyDsConfigVersionIdWithRules${jsonToQuery(query)}`,
      {
        method: REQUEST_METHODS.POST,
        // body: data,
        showToast: false,
      }
    ),
  getDSConfigVersionById: (query) =>
    doFetch(`/dsConfigVersion/getDSConfigVersionById${jsonToQuery(query)}`),
  getAllDSConfigVersion: (query) =>
    doFetch(`/dsConfigVersion/getAllDSConfigVersion${jsonToQuery(query)}`),
  getAllDSConfigVersionByDSConfigId: (query) =>
    doFetch(
      `/dsConfigVersion/getAllDSConfigVersionByDSConfigId${jsonToQuery(query)}`
    ),
  deleteDSConfigVersionById: (query) =>
    doFetch(`/dsConfigVersion/deleteDSConfigVersionById${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.DELETE,
    }),
};
