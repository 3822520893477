import { useCallback } from "react";

const ChipInput = ({
  noPad,
  formGroupClassName,
  label,
  required,
  disabled,
  disabledCheck,
  placeholder,
  inputProps,
  chipList = [],
  onChangeValue,
  renderCheckbox,
  checked,
  onCheckChanged,
  hideInput,
}) => {
  const onAddChip = useCallback(
    (value) => {
      if (onChangeValue) {
        onChangeValue([
          ...chipList,
          {
            label: value,
          },
        ]);
      }
    },
    [chipList, onChangeValue]
  );

  const onRemoveChip = useCallback(
    (item, index) => {
      if (onChangeValue) {
        const temp = Array.from(chipList);
        temp.splice(index, 1);

        onChangeValue(temp);
      }
    },
    [chipList, onChangeValue]
  );

  const onKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        console.log(e.target.value);
        if (e.target.value.trim()) {
          onAddChip(e.target.value);
          e.target.value = "";
        }
      }
    },
    [onAddChip]
  );

  return (
    <div
      className={["form-group", noPad ? "mb-0" : "", formGroupClassName]
        .filter((e) => e)
        .join(" ")}
    >
      {label && (
        <label
          className={["form-label", !hideInput ? "mb-2" : "mb-0"].join(" ")}
          htmlFor="chipInputEnable"
        >
          {renderCheckbox ? (
            <div className="form-group w-auto mb-0 ms-1">
              <div className="form-check w-auto">
                <input
                  type="checkbox"
                  id="chipInputEnable"
                  className="form-check-input"
                  checked={checked}
                  disabled={disabledCheck}
                  onChange={(e) => {
                    if (onCheckChanged) {
                      onCheckChanged(e.target.checked);
                    }
                  }}
                />
                {label}
              </div>
            </div>
          ) : (
            label
          )}
          {required && (
            <sup>
              <i
                className="fa-solid fa-star-of-life"
                style={{
                  color: "red",
                  fontSize: "5px",
                  marginBottom: "5px",
                }}
              ></i>
            </sup>
          )}
        </label>
      )}
      {!hideInput && (
        <input
          type="text"
          className="form-control"
          {...{ ...inputProps, required }}
          /*  onSubmit={(e) => {
          if (e.target.value.trim()) {
            onAddChip(e.target.value.trim());
          }
        }} */
          placeholder={placeholder ?? "Write option and hit enter to add"}
          onKeyUp={onKeyPress}
          disabled={disabled}
        />
      )}
      <div className={`d-flex flex-wrap ${disabled ? "inactive-div" : ""}`}>
        {chipList.map((e, index) => (
          <div key={`chip-list-${index}`} className="chip-view">
            {e.label}
            <i
              className="fa-solid fa-xmark cursor-pointer"
              onClick={() => {
                onRemoveChip(e, index);
              }}
            ></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChipInput;
