import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_METHODS } from "../fetcher";

export default {
  saveOrUpdateRules: (data) =>
    doFetch("/rules/saveOrUpdateRules", {
      method: REQUEST_METHODS.POST,
      body: data,
      showToast: false,
    }),
  updatePriorityForRules: (data) =>
    doFetch("/rules/updatePriorityForRules", {
      method: REQUEST_METHODS.POST,
      body: data,
      showToast: false,
    }),
  statusChangeForRules: (query) =>
    doFetch(`/rules/statusChangeForRules`, {
      method: REQUEST_METHODS.POST,
      body: query,
      showToast: false,
    }),
  getAllRules: (query) => doFetch(`/rules/getAllRules${jsonToQuery(query)}`),
  getGroupNameByGroup: (query) =>
    doFetch(`/rules/getGroupNameByGroup${jsonToQuery(query)}`),
  getAllRulesByDSConfigVersionId: (query) =>
    doFetch(`/rules/getAllRulesByDSConfigVersionId${jsonToQuery(query)}`),
  getResultReportByDSConfigId: (query) =>
    doFetch(`/rules/getResultReportByDSConfigId${jsonToQuery(query)}`),

  getRulesById: (query) => doFetch(`/rules/getRulesById${jsonToQuery(query)}`),
  deleteRulesById: (query) =>
    doFetch(`/rules/markDeleteUnDeleteForRules`, {
      body: query,
      method: REQUEST_METHODS.POST,
    }),
  // deleteRulesById: (query) =>
  //   doFetch(`/rules/deleteRulesById${jsonToQuery(query)}`, {
  //     method: REQUEST_METHODS.DELETE,
  //   }),

  // for rule types
  getAllRulesType: (query) =>
    doFetch(`/rulesType/getAllRulesType${jsonToQuery(query)}`),
  getNextPriorityForRules: (query) =>
    doFetch(`/rules/getNextPriorityForRules${jsonToQuery(query)}`),
};
