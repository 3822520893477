import companyUserTypes from "../actionTypes";
import { types } from "../../../auth/store";
import companyUserApi from "../../../../services/apis/companyUserApi";

export function companyUserListAction(body) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await companyUserApi.getAllCompanyUser(body);
      dispatch({
        type: companyUserTypes.COMPANY_USER_LIST,
        payload: {
          companyUserListData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw error;
    }
  };
}

export function companySaveOrUpdateAction(body) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response =
        await companyUserApi.saveOrUpdateGeographicalAreaControl(body);
      dispatch({
        type: companyUserTypes.SAVE_OR_UPDATE_COMPANY,
        payload: {
          companyUserSaveOrUpdateData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw error;
    }
  };
}

export function companyUserDetailsAction() {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await companyUserApi.getCompanyUserDetailsById({
        companyUserId: localStorage.getItem("companyUserId"),
      });
      dispatch({
        type: companyUserTypes.COMPANY_USER_DETAILS,
        payload: {
          companyUserDetailsData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw error;
    }
  };
}
export function companyDeleteUserIdAction(companyUserId) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await companyUserApi.deleteCompanyUserById({
        companyUserId,
      });
      dispatch({
        type: companyUserTypes.DELETE_COMPANY_USERID,
        payload: {
          companyDeleteUserIdData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw error;
    }
  };
}
