import { KEYSET_GROUP_NAME } from "../../constants";
import {
  getFinalJsonWithKeyValue,
  getTypeForcefully,
  getUUID,
  getWidgetTypeBasedOnFieldType,
  groupByJson,
} from "../coreUtils";
import RuleBuilderConvert from "./RuleBuilderConvert";

function fieldConfigResToForm(dataStreamConfigId, schemaId) {
  return (form) => {
    const fieldOptions = form?.fieldOptions ?? "";
    return {
      formId: getUUID(),
      aggregateFunctionId: form?.aggregateFunctionId
        ? form?.aggregateFunctionId
        : -1,
      pathName: form?.contextPath ?? "",
      isOptionalField: form?.isOptionalField ?? false,
      groupName: form?.customFieldGroup ?? "",
      customFieldsId: form?.customFieldsId ?? 0,
      dataStreamConfigId: dataStreamConfigId ?? form?.dataStreamConfigId,
      dataStreamConfigApiId: form?.dataStreamConfigApiId,
      schemaId: schemaId ?? form?.schemaId ?? 0,
      groupId: form?.groupId ?? 0,
      fieldName: form?.name ?? "",
      parentHeader: form?.parentHeader ?? "",
      queryString: form?.queryString ?? "",
      isActive: form?.isActive ?? true,
      isDeleted: form?.isDeleted ?? false,
      parentPathName: form?.parentOfChild,
      lastSelectedChild: form?.lastSelectedChild,
      isPrimarySecondary: form?.isPrimarySecondary ?? "",
      createdType: form?.createdType,
      selectedGroupByPath: form?.groupBy ?? "",
      fieldType: form?.fieldType ?? "",
      queryTree: form?.queryTree ?? "",
      sqlWhere: form?.sqlWhere ?? "",
      fieldOptionsEnable: form?.fieldOptionsEnable ?? false,
      fieldOptions:
        fieldOptions.trim().length > 0
          ? fieldOptions.split(",").map((e) => ({
              label: e.trim(),
            }))
          : [],
      geographicalField: form?.geographicalField ?? false,
      /* whereClause: (form?.whereClauses ?? []).map((where, index) => ({
        column: where.leftOperand,
        operator: where.operator,
        expectedValue: where.rightOperand,
        midOperator: where.midOperator,
        expressionId: where.expressionId ?? 0,
        id: where.id ?? 0,
        customFieldsId: where?.customFieldsId ?? 0,
      })), */
      query: RuleBuilderConvert.aiccRBWhereClauseToQuery(
        form?.whereClauses,
        true
      ),
    };
  };
}

function joinFields(fields = [], joinBy = ".", noFilter = false) {
  return fields.filter((e) => (!noFilter ? e : true)).join(joinBy);
}

function sortEveryone(item) {
  if (item.fields <= 0) {
    return item;
  }
  return {
    ...item,
    fields: item.fields
      .sort(function (one, other) {
        return one.fields.length - other.fields.length;
      })
      .map(sortEveryone),
  };
}

function checkAndContinueFieldReading(data, parent = "", useParent = false) {
  // console.log(useParent);
  if (Array.isArray(data)) {
    // combine all objs in same json
    const finalJson = getFinalJsonWithKeyValue(data);
    return useParent
      ? [
          {
            name: parent,
            fields: checkAndContinueFieldReading(finalJson ?? {}, parent),
            type: typeof data,
            path: joinFields([parent]),
          },
        ]
      : readMyJson(finalJson ?? {}, parent);
  } else if (typeof data === "object") {
    return useParent
      ? [
          {
            name: parent,
            fields: readMyJson(data, parent),
            type: typeof data,
            path: joinFields([parent]),
          },
        ]
      : readMyJson(data, parent);
  } else {
    return {
      name: data,
      fields: [],
      // type: typeof data,
      type: getTypeForcefully(data),
      path: joinFields([parent, data]),
    };
  }
}

function readMyJson(data, parent) {
  return Object.keys(data).map((key) => {
    // if given data is string then it will return it directly from here.
    if (!Array.isArray(data[key]) && typeof data[key] !== "object") {
      return {
        name: key,
        fields: [],
        path: joinFields([parent, key]),
        type: getTypeForcefully(data[key]),
      };
    }

    // if given data is array or object it will continue to read
    return {
      name: key,
      fields:
        data[key] === null
          ? []
          : // check if next key is array or not.
            // if it is array then check first object data type.
            // if first element data type is string then return key directly.
            Array.isArray(data[key]) && typeof data[key][0] === "string"
            ? []
            : checkAndContinueFieldReading(
                data[key],
                joinFields([parent, key])
              ),
      type: Array.isArray(data[key])
        ? "array"
        : data[key] === null
          ? "any"
          : "object",
      path: joinFields([parent, key]),
    };
  });
}

function mapParentChildConfig(list = []) {
  return list.reduce((prev, curr) => {
    prev[curr.path] = curr;

    return {
      ...prev,
      ...mapParentChildConfig(curr.fields),
    };
  }, {});
}

export default {
  fieldConfigFormToReq(
    dataStreamConfigId,
    schemaId,
    { isApiSchema = false, mappedFieldJson = {} }
  ) {
    return (form) => {
      return {
        aggregateFunctionId: form?.aggregateFunctionId
          ? form?.aggregateFunctionId
          : -1,
        contextPath: form?.pathName ?? "",
        customFieldGroup: form?.groupName ?? "",
        fieldType: getWidgetTypeBasedOnFieldType(form?.fieldType ?? ""),
        customFieldsId: form?.customFieldsId ?? 0,
        isOptionalField: form?.isOptionalField ?? false,

        dataStreamConfigId:
          /* isApiSchema
          ? undefined
          : */ dataStreamConfigId ?? form?.dataStreamConfigId ?? 0,
        dataStreamConfigApiId: isApiSchema ? schemaId : undefined,

        schemaId: schemaId ?? form?.schemaId ?? 0,
        groupId: form?.groupId ?? 0,
        name: form?.fieldName ?? "",
        parentOfChild: form?.parentPathName ?? "",
        lastSelectedChild: form?.selectedChild?.path ?? "",
        parentHeader: form?.parentHeader ?? "",
        queryString: "",
        groupBy: form?.selectedGroupByPath ?? "",
        isPrimarySecondary: form?.isPrimarySecondary ?? "",
        queryTree: form?.queryTree ?? "",
        fieldOptionsEnable: form?.fieldOptionsEnable ?? false,
        fieldOptions: (form?.fieldOptions ?? [])
          .map((e) => e.label.trim())
          .join(","),
        sqlWhere: form?.sqlWhere ?? "",
        isActive: form?.isActive ?? true,
        isDeleted: form?.isDeleted ?? false,
        geographicalField: form?.geographicalField ?? false,
        /* whereClauses: (form?.whereClause ?? [])
          .filter((e) => Boolean(e.column))
          .map((e, index) => ({
            leftOperand: e.column,
            operator: e.operator,
            rightOperand: e.expectedValue,
            midOperator: e.midOperator,
            expressionOrder: index,
            isActive: true,
            isDeleted: e.isDeleted ?? false,
            expressionId: e.expressionId ?? 0,
            id: e.id ?? 0,
            customFieldsId: form?.customFieldsId ?? 0,
          })), */
        whereClauses: RuleBuilderConvert.aiccRBQueryToWhereClause(
          form?.query,
          true,
          mappedFieldJson,
          true
        ),
      };
    };
  },
  fieldConfigResListToForm(list = []) {
    const groupedJson = groupByJson(list, "groupId");

    return Object.keys(groupedJson)
      .map((e, index) => {
        const isPrimarySecondary = groupedJson[e][0].groupId === -1;
        return {
          name: isPrimarySecondary
            ? KEYSET_GROUP_NAME
            : groupedJson[e][0]?.customFieldGroup,
          order: isPrimarySecondary ? -1 : index,
          children: (groupedJson[e] ?? [])
            .map(fieldConfigResToForm())
            .filter((ele) => {
              return isPrimarySecondary ? ele.isPrimarySecondary : true;
            }),
        };
      })
      .sort(function (a, b) {
        return a.order - b.order;
      });
  },
  newConvertFunction(uploadData, dataStreamName = "") {
    let innerJson = [];
    if (!Array.isArray(uploadData) && typeof uploadData === "object") {
      innerJson = readMyJson(uploadData);
    }

    const shouldAssignParent = !innerJson.some((e) => e.fields.length > 0);

    const finalJson = checkAndContinueFieldReading(
      uploadData,
      Array.isArray(uploadData) || shouldAssignParent ? dataStreamName : "",
      Array.isArray(uploadData) || shouldAssignParent
    );

    // console.log(JSON.stringify(finalJson), finalJson.some(e => e.fields.length > 0));
    // finalJson.some(e => e.fields.length > 0)
    return finalJson
      .sort(function (one, other) {
        return one.fields.length - other.fields.length;
      })
      .map(sortEveryone);
  },
  parentChildConfigJson(list = []) {
    return mapParentChildConfig(list);
  },
};
