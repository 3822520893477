import React, { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./SchemaStyle.scss";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import openApi from "../../../services/apis/openApi";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import {
  getFieldsList,
  getLastBySplit,
  getParentPathBySplit,
  getPathPrefix,
  getUUID,
  nameCapitalize,
} from "../../../utils/coreUtils";
import CustomModal from "../../../components/Modals/CustomModal";
import CacheUtils from "../../../utils/CacheUtils";
import CommonFieldConfig from "../../../components/common/CommonFieldConfig";
import FieldConfigConvert from "../../../utils/converter/FieldConfigConvert";
import customFields from "../../../services/apis/customFields";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";
import { useTranslation } from "react-i18next";
import { KEYSET_GROUP_NAME } from "../../../constants";
import PopUpModel from "./PopUpModel";
import { GroupMaker, ListMaker } from "./FieldConfigUI";
import CenterLoading from "../../../components/common/CenterLoading";
import RuleBuilderConvert from "../../../utils/converter/RuleBuilderConvert";
// Test URL
// http://localhost:3000/DataStream/FieldConfig/1671121210315/44
var isobject = function (x) {
  return Object.prototype.toString.call(x) === "[object Object]";
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const listItems = (finalList, handleClick, dragStart) =>
  finalList?.map((item) => {
    return ListMaker(item, handleClick, dragStart);
  });

// var getkeys = function (obj, prefix) {
//   var keys = Object.keys(obj);
//   prefix = prefix ? prefix + "." : "";
//   return keys.reduce(function (result, key) {
//     if (isobject(obj[key])) {
//       if (isNumeric(key)) {
//         result = result.concat(
//           getkeys(obj[key], `${prefix.slice(0, prefix.length - 1)}[${key}]`)
//         );
//       } else {
//         result = result.concat(getkeys(obj[key], prefix + key));
//       }
//     } else if (
//       !isobject(obj[key]) &&
//       typeof obj[key] != "string" &&
//       obj[key] &&
//       obj[key].length > 0
//     ) {
//       result = result.concat(getkeys(obj[key], `${prefix}${key}5`));
//     } else {
//       result.push(prefix + key);
//     }
//     return result;
//   }, []);
// };

function FieldConfigJson() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingFieldConfig, setLoadingFieldConfig] = useState(false);

  const [t] = useTranslation();

  const [mainConfigJson, setMainConfigJson] = useState(null);
  // const [companyDetails, setCompanyDetails] = useState();
  const [dataStreamDetails, setDataStreamDetails] = useState();
  const [mappedFieldJson, setMappedFieldJson] = useState({});
  const [schemaList, setSchemaList] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState();
  // const [newData, setnewData] = useState([]);

  const [originalGroupData, setOriginalGroupData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [ruleBuilderFields, setRuleBuilderFields] = useState([]);

  const [groupNameList, setGroupNameList] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [noKeyAlert, setNoKeyAlert] = useState(false);
  const [goToBack, setGoToBack] = useState(false);
  const [changeSchemaTo, setChangeSchemaTo] = useState();
  const [viewPopUp, setViewPopUp] = useState(false);
  const [item, setItem] = useState();
  const dragItem = useRef();
  // const dragOverItem = useRef();
  let dropData;

  const [modalData, setModalData] = useState({
    parentHeader: "",
    fieldName: "",
    pathName: "",
    groupName: "",
    fieldType: "",
    type: "",
    indexNumber: "",
    isOptionalField: false,
    aggregateFunctionId: -1,
    selectedChild: undefined,
    fields: [],
    fieldList: [],
    aggFieldList: [],
    groupByList: undefined,
    whereClause: [], // empty object should be assigned
  });
  const [isSavingData, setIsSavingData] = useState(false);
  const [groupModalData, setGroupModalData] = useState({
    prevGroupName: "",
    groupName: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const drop = (result) => {
    if (result.destination?.droppableId.indexOf("group-drop-area") > -1) {
      const groupName = result.destination?.droppableId.split("|")[1];
      dropData = null;
      findItemById(result.draggableId, mainConfigJson);
      if (dropData != null) {
        const groupList = groupData.filter(
          (group) => group.name.toLowerCase() === groupName.toLowerCase()
        );
        if (groupList.length > 0) {
          /* const saveData = {
            ...modalData,
            fieldName: dropData.name,
            pathName: dropData.path,
            groupName: groupName,
            parentHeader: dropData.name,
            fields: dropData.fields,
            indexNumber: "",
            aggregateFunctionId: -1,
            fieldList: [],
            aggFieldList: [],
            groupByList: undefined,
            fieldType: dropData.type,
            queryTree: "",
            sqlWhere: "",
            dropped: true,
            geographicalField: false,
          }; */

          handleClickModal({
            ...dropData,
            groupName: groupName,
            fieldName: dropData.name,
            pathName: dropData.path,
            dropped: true,
          });
          // setModalData(saveData);
          // setShowModal(true);
          // handleSave(saveData);
        }
        setGroupData([...groupData]);
      }
    }
  };

  const findItemById = (fieldId, group) => {
    fieldId = fieldId.replaceAll("group-", "");
    if (group != null) {
      if (group.path === fieldId) {
        dropData = group;
        return;
      }
      if (group.fields != null) {
        return group.fields.forEach((filed) => findItemById(fieldId, filed));
      }
      if (group instanceof Array)
        return group.forEach((grp) => findItemById(fieldId, grp));
    }
  };

  const removeFieldFromAllGroup = (pathName, keyToCheck = "pathName") => {
    return groupData.filter((group) => {
      group.children = group.children.filter((child) => {
        return !(child[keyToCheck] === pathName);
      });
      return group;
    });
  };

  const checkIfFieldInOtherGroup = (mData) => {
    if (groupData.length > 0) {
      return (
        groupData?.filter((group) =>
          group.children?.filter((child) => child.formId === mData.formId)
        ).length > 0
      );
    }
    return false;
  };

  const handleGroupSave = (mData) => {
    let groupLocalData = Array.from(groupData);
    const foundedIndex = groupLocalData.findIndex(
      (group) => group.name === mData.prevGroupName
    );
    if (foundedIndex > -1) {
      groupLocalData[foundedIndex].name = mData.groupName;
      groupLocalData[foundedIndex].children = groupLocalData[
        foundedIndex
      ].children.map((child) => ({
        ...child,
        groupName: mData.groupName,
        isUpdated: true,
      }));
    } else {
      groupLocalData.push({
        name: mData.groupName,
        children: [],
      });
    }
    /* let groupLocalData = groupData;
    groupLocalData.forEach((group) => {
      if (group.name === mData.prevGroupName) {
        group.name = mData.groupName;
      }
    }); */
    setGroupData([...groupLocalData]);
    // document.getElementById("btn-close")[0].click();
    setShowGroupModal(false);
  };

  /**
   * Called on save button click of the popup.
   * @param {*} mData
   * @returns
   */
  const handleSave = (mData) => {
    let groupLocalData = Array.from(groupData);

    if (mData.isPrimarySecondary) {
      const findKeySetGroup = groupLocalData.findIndex((e) => e.order === -1);

      mData = {
        ...mData,
        groupId: -1,
      };

      if (findKeySetGroup === -1) {
        groupLocalData.splice(0, 0, {
          name: KEYSET_GROUP_NAME,
          order: -1,
          children: [mData],
        });
      } else {
        const alreadyExistIndex = groupLocalData[
          findKeySetGroup
        ].children.findIndex(
          (e) => e.isPrimarySecondary === mData.isPrimarySecondary
        );

        if (alreadyExistIndex === -1) {
          groupLocalData[findKeySetGroup].children.push(mData);
        } else {
          groupLocalData[findKeySetGroup].children[alreadyExistIndex] = {
            ...groupLocalData[findKeySetGroup].children[alreadyExistIndex],
            ...mData,
          };
        }
      }

      setGroupData([
        ...groupLocalData /* .filter((e) => e.children.length > 0) */,
      ]);
      return;
    }

    if (checkIfFieldInOtherGroup(mData)) {
      groupLocalData = removeFieldFromAllGroup(mData.formId, "formId");
    }
    const groupList = groupLocalData.filter(
      (group) => group.name.toLowerCase() === mData.groupName.toLowerCase()
    );
    if (groupList.length > 0) {
      groupList[0].children.push(mData);
    } else {
      groupLocalData.push({
        name: mData.groupName,
        children: mData.fieldName.trimStart() ? [mData] : [],
      });
    }
    setGroupData([...groupLocalData]);
    // document.getElementsByClassName("btn-close")[0].click();
    setShowModal(false);
  };

  const handleLevelClick = (selectedOption, idx) => {
    const childConfig = JSON.parse(selectedOption.target.value);
    modalData.fieldList.length = idx;
    modalData.fieldList.push(childConfig);
    setModalData({
      ...modalData,
      pathName: childConfig.path,
    });
  };

  const handleGroupClick = (item) => {
    // window.$("#SimpleGroupJsonPathModal").modal("show");
    setShowGroupModal(true);
    setGroupModalData({ groupName: item.name, prevGroupName: item.name });
  };

  const handleGroupItemClick = (item) => {
    // console.log(item);
    const groupByList = mappedFieldJson[getParentPathBySplit(item.pathName)];
    const parentList = mappedFieldJson[item.parentPathName];
    const selectedChild = mappedFieldJson[item.lastSelectedChild];

    setModalData({
      fieldName: "",
      groupName: undefined,
      // whereClause: [{ ...initialWhereClause, id: new Date().getTime() }],
      geographicalField: false,
      ...item,
      ...parentList,
      selectedChild,
      groupByList,
      fieldList: getFieldsList(
        item.parentPathName,
        item.selectedChild?.path ?? item.lastSelectedChild,
        mappedFieldJson
      ),
      dropped: undefined,
      userGrouping: true,
    });
    // window.$("#SimpleJsonPathModal").modal("show");
    setShowModal(true);
  };

  const deleteFieldFromList = (item, type) => {
    const tempList = Array.from(groupData);
    const mappedObj = tempList.map((e) =>
      e.children.findIndex(
        (child) =>
          child.fieldName === item.fieldName &&
          child.customFieldsId === item.customFieldsId
      )
    );

    const parentIndex = mappedObj.findIndex((e) => e > -1);

    if (parentIndex === -1) return;

    const childIndex = mappedObj[parentIndex];

    if (childIndex === -1) return;

    const obj = tempList[parentIndex].children[childIndex];

    tempList[parentIndex].children[childIndex].isDeleted =
      type === "undo" ? false : true;

    /*  if (obj.customFieldsId) {
      tempList[parentIndex].children[childIndex].isDeleted =
        type === "undo" ? false : true;
    } else {
      tempList[parentIndex].children.splice(childIndex, 1);
    } */

    console.log(tempList);

    setGroupData(tempList);
  };

  const handleDeleteClick = async (item, type) => {
    if (type === "undo") {
      deleteFieldFromList(item, type);
      return;
    }

    if (!item.customFieldsId) {
      deleteFieldFromList(item);
      return;
    }

    const deleteCustomField =
      await customFields.deleteCustomFieldsByIdWithCheckingRules({
        customFieldsId: item.customFieldsId,
      });
    setViewPopUp(deleteCustomField.data.isActiveRecordFound);

    if (!deleteCustomField.data.isActiveRecordFound) {
      deleteFieldFromList(item);
    }
    // setViewPopUp(true);
    setItem(item);
  };

  const handleClickModal = (item, type) => {
    // console.log(item);
    let groupByList = mappedFieldJson[getParentPathBySplit(item.path)];

    const groupDataToSave = {
      // ...modalData,
      isOptionalField: false,
      formId: item.formId ?? getUUID(),
      fieldName: getLastBySplit(item.name ?? item?.fieldName ?? ""),
      groupName: item.groupName ? item.groupName : undefined,
      groupId:
        groupNameList.find((group) => group?.groupName === item.groupName)
          ?.groupId ?? 0,
      dropped: item.dropped ? item.dropped : undefined,
      type: item.type,
      pathName: item.path,
      dataStreamConfigApiId: selectedSchema?.dataStreamConfigApiId,

      // for child component selection. To find parent list for child components
      parentPathName: item.path,

      parentHeader: item.name,
      fields: item.fields,
      isParent: type === "parent",
      // fieldList: groupByList ? [groupByList] : [],
      fieldList: [],
      aggFieldList: [],
      // selectedChild: item,
      selectedChild: undefined,
      geographicalField: false,
      selectedGroupByPath: undefined,
      groupByList,
      aggregateFunctionId: -1,
      whereClause: [], // empty object should be assigned
      fieldType: item.type,
      queryTree: "",
      sqlWhere: "",
    };

    setModalData(groupDataToSave);
    // window.$("#SimpleJsonPathModal").modal("show");
    setShowModal(true);
  };

  // const uploadFile = (e) => {
  //   const fileReader = new FileReader();
  //   var newData = [];
  //   fileReader.readAsText(e.target.files[0], "UTF-8");
  //   fileReader.onload = (e) => {};
  // };

  const fetchJson = async (schema) => {
    // console.log(schema);
    setLoadingContent(true);
    const url = schema.awsUrl;
    try {
      const cachedResponse = await CacheUtils.getCacheResponseTTL(
        "dataStreamFile",
        url
      );

      const langData = cachedResponse
        ? { data: cachedResponse }
        : await axios.get(url);

      if (!cachedResponse) {
        CacheUtils.cacheResponseTTL("dataStreamFile", url, langData.data);
      }

      const finalJson = FieldConfigConvert.newConvertFunction(
        langData.data,
        schema?.jsonFileSchemaName
      );

      setMainConfigJson(finalJson);

      setMappedFieldJson(FieldConfigConvert.parentChildConfigJson(finalJson));
      setLoadingContent(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch schema details from the server.");
      setLoadingContent(false);
    }
  };

  const getDataStreamById = async () => {
    setLoadingContent(true);
    try {
      const { data } = await openApi.getDataStreamConfig();

      // await getCompanyUserDetailsById();
      if (typeof data !== "string") {
        // setDataStreamDetails()
        // setSchemaList((data ?? []));
        const founded = data.find(
          (e) => e.dataStreamConfigId == params?.dataStreamConfigId
        );
        console.log("founded", founded);
        if (founded) {
          setDataStreamDetails(founded);
          setSchemaList(founded?.aiccDataStreamJsons ?? []);

          if (founded?.aiccDataStreamJsons[0]) {
            setSelectedSchema({
              ...founded?.aiccDataStreamJsons[0],
              label: nameCapitalize(
                founded?.aiccDataStreamJsons[0].jsonFileSchemaName
              ),
              value: founded?.aiccDataStreamJsons[0].jsonId,
            });

            fetchJson(founded?.aiccDataStreamJsons[0]);
          } else {
            setLoadingContent(false);
          }
        } else {
          setLoadingContent(false);
        }
      } else {
        setLoadingContent(false);
      }
    } catch (error) {
      setLoadingContent(false);
    }
    // setIsLoading(false);
  };

  const getGroupNameList = async (dataStreamConfigId, schemaId) => {
    // console.log(selectedSchema);
    try {
      const response = selectedSchema?.dataStreamConfigApiId
        ? await customFields.getGroupNameByDataStreamConfigIdAndDataStreamConfigApiId(
            {
              dataStreamConfigId,
              dataStreamConfigApiId: selectedSchema?.dataStreamConfigApiId,
            }
          )
        : await customFields.getGroupNameByDataStreamConfigIdAndSchemaId({
            dataStreamConfigId,
            schemaId,
          });

      setGroupNameList(response.data);
    } catch (error) {
      /* empty */
    }
  };

  const getFieldConfigJson = async (dataStreamConfigId, schemaId) => {
    if (!dataStreamConfigId || !schemaId) {
      return;
    }
    setIsSavingData(true);
    setLoadingFieldConfig(true);
    try {
      getGroupNameList(dataStreamConfigId, schemaId);

      const response =
        await customFields.getCustomFieldsByDataStreamConfigIdAndSchemaId({
          dataStreamConfigId,
          schemaId,
        });

      const configData = FieldConfigConvert.fieldConfigResListToForm(
        response.data
      );

      // console.log(configData);
      setRuleBuilderFields(
        RuleBuilderConvert.aiccRBFieldConfigToGroupedFields([...response.data])
      );
      setGroupData(configData);
      setOriginalGroupData(JSON.stringify(configData));
    } catch (error) {
      setOriginalGroupData([]);
    }
    setIsSavingData(false);
    setLoadingFieldConfig(false);
  };

  // const checkIfKeyAssigned = () => {
  //   const assignedKey = groupData
  //     .filter((e) => e.children.some((ele) => ele.isPrimarySecondary))
  //     .flatMap((e) => e.children);

  //   return assignedKey.length === 2;
  // };

  const saveFields = async (refresh = true) => {
    /* if (!checkIfKeyAssigned()) {
      setNoKeyAlert(true);
      return;
    } */

    setLoadingContent(true);
    try {
      setIsSavingData(true);
      const fieldList = groupData
        .flatMap((e) => e.children)
        .filter((e) =>
          searchParams.get("save") === "1"
            ? true
            : !e.customFieldsId
              ? !e.isDeleted
              : !e.customFieldsId || e.isUpdated || e.isDeleted
        )
        .map(
          FieldConfigConvert.fieldConfigFormToReq(
            params?.dataStreamConfigId,
            selectedSchema.value,
            {
              isApiSchema: selectedSchema?.dataStreamConfigApiId ? true : false,
            }
          )
        );

      // console.log(JSON.stringify(fieldList, null, 2));

      /* const allRes = await Promise.all(
        fieldList.map((e) => customFields.saveOrUpdateCustomFields(e))
      ); */

      /* for (let i = 0; i < fieldList.length; i++) {
        await customFields.saveOrUpdateCustomFields(fieldList[i]);

        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 200);
        });
      } */

      await customFields.saveOrUpdateCustomFieldsList(fieldList);

      if (refresh)
        getFieldConfigJson(params?.dataStreamConfigId, selectedSchema?.value);

      if (goToBack) {
        navigate(getPathPrefix("dashboard"));
      }
      toast.success("Configuration saved!!");
      // console.log(allRes);
    } catch (error) {
      setIsSavingData(false);
    }
    setLoadingContent(false);
  };

  useEffect(() => {
    getFieldConfigJson(params?.dataStreamConfigId, selectedSchema?.value);
  }, [selectedSchema, params?.dataStreamConfigId]);

  useEffect(() => {
    if (params?.dataStreamConfigId !== undefined) {
      getDataStreamById();
    }
    if (params?.companyId !== undefined) {
      // getCompanyById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.dataStreamConfigId, params?.companyId]);

  const schemaSelectOptions = useMemo(() => {
    const apiSchema = schemaList.map((e) => ({
      ...e,
      label: nameCapitalize(e.jsonFileSchemaName),
      value: e.jsonId,
    }));
    return apiSchema;
  }, [schemaList]);

  const filteredGroupData = useMemo(() => {
    return groupData.map((e) => ({
      ...e,
      // children: e.children.filter((ele) => !ele.isDeleted),
    }));
  }, [groupData]);

  const onlyGroupOptions = useMemo(() => {
    return [
      ...(groupData ?? [])
        .map((e) => ({
          ...e,
          label: e.name,
          value: e.name,
        }))
        .filter((e) => e.order > -1),
      ...[
        ...new Set(
          (filteredGroupData ?? [])
            .filter((e) => !e?.order)
            .flatMap((e) => e?.children)
            .filter((e) => !e?.groupId || e?.groupId === 0)
            .map((e) => e.groupName)
        ),
      ].map((e) => ({
        groupId: undefined,
        label: e,
        value: e,
      })),
    ];
  }, [groupData, filteredGroupData]);

  return (
    <div>
      <PopUpModel
        show={viewPopUp}
        item={item}
        onClose={() => {
          setViewPopUp(false);
        }}
      />
      <CustomModal
        show={showDelete}
        centered
        noHeader
        body={
          <h5
            className="modal-title text-center w-100"
            style={{ color: "#004e5c" }}
          >
            {t("fieldConfiguration.saveConfirmationPopup.message", {
              schemaName: selectedSchema?.label,
            })}
          </h5>
        }
        okTitle={t("CommonKeys.yes")}
        cancelTitle={t("CommonKeys.no")}
        centerFooter
        onOk={async () => {
          // deleteHandler(companyToDelete?.companyId);
          await saveFields(false);
          if (!goToBack) {
            try {
              await fetchJson(changeSchemaTo);
            } catch (error) {
              /* empty */
            }
            setSelectedSchema(changeSchemaTo);
            setGroupData([]);
            setShowDelete(false);
          }
        }}
        onCancel={async () => {
          if (goToBack) {
            navigate(getPathPrefix("dashboard"));
          } else {
            try {
              await fetchJson(changeSchemaTo);
            } catch (error) {
              /* empty */
            }
            setSelectedSchema(changeSchemaTo);
            setGroupData([]);
            setShowDelete(false);
          }
        }}
      />

      <CustomModal
        show={noKeyAlert}
        centered
        title={t("fieldConfiguration.noKeyAssignedAlert.title")}
        body={
          <h5
            className="text-danger fw-bold text-center w-100 fs-6"
            style={{ color: "#004e5c" }}
          >
            {t("fieldConfiguration.noKeyAssignedAlert.message")}
          </h5>
        }
        okTitle={t("CommonKeys.okButtonTitle")}
        centerFooter
        onOk={async () => {
          setNoKeyAlert(false);
        }}
      />

      <CenterLoading asModal isVisible={loadingContent || loadingFieldConfig} />

      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper p-0">
          <div className="main-panel">
            <div className="content-wrapper">
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={dataStreamDetails?.customDataStreamName}
                items={t("fieldConfiguration.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              <div className="col-12 mt-4 mx-auto">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12">
                        <div className="card-label">
                          <img
                            src="/assets/images/document.svg"
                            alt=""
                            className="me-2"
                            style={{
                              height: "22px",
                              width: "22px",
                            }}
                          />{" "}
                          {t("fieldConfiguration.breadCrumb.title")} -{" "}
                          {dataStreamDetails?.customDataStreamName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {/* <h6
                          className="main-content-label"
                          style={{
                            color: "#3c4858",
                          }}
                        >
                          {dataStreamDetails?.customDataStreamName}
                        </h6> */}
                        <ReactSelect
                          classNamePrefix="select"
                          value={selectedSchema}
                          options={schemaSelectOptions}
                          isOptionSelected={(option) => {
                            return option.value === selectedSchema.value;
                          }}
                          // isDisabled={!location?.state?.data}
                          onChange={async (e) => {
                            if (e.value === selectedSchema) {
                              return;
                            }
                            if (
                              originalGroupData !== JSON.stringify(groupData)
                            ) {
                              setShowDelete(true);
                              setChangeSchemaTo(e);
                            } else {
                              try {
                                await fetchJson(e);
                                setSelectedSchema(e);
                              } catch (error) {
                                /* empty */
                              }
                            }
                          }}
                          /* onChange={async (e) => {
                          try {
                            await fetchJson(e.awsUrl);
                            setSelectedSchema(e?.value);
                          } catch (error) {}
                        }} */
                        />
                      </div>
                    </div>

                    <div className="form">
                      <DragDropContext onDragEnd={drop}>
                        <div className="row mt-4">
                          <div className="col-lg-6 col-md-12 col-12">
                            <div className="card field-configurator-box radius-15">
                              <div className="card-header">
                                {t("fieldConfiguration.leftSideSection.title")}
                              </div>
                              <div className="card-body overflow-auto">
                                <div className="field-configurator-path">
                                  <Droppable droppableId="droppable">
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {mainConfigJson &&
                                          listItems(
                                            mainConfigJson,
                                            handleClickModal,
                                            dragStart
                                          )}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12">
                            <div className="card field-configurator-box radius-15">
                              <div className="card-header">
                                {t("fieldConfiguration.rightSideSection.title")}
                              </div>
                              <div className="card-body overflow-auto">
                                <div className="field-configurator-path user-group">
                                  {filteredGroupData &&
                                    GroupMaker(
                                      filteredGroupData,
                                      handleGroupClick,
                                      handleGroupItemClick,
                                      handleDeleteClick
                                    )}
                                  {/* <Droppable droppableId="droppable">
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {groupData &&
                                          GroupMaker(
                                            groupData,
                                            handleGroupClick,
                                            handleGroupItemClick,
                                            handleDeleteClick
                                          )}
                                      </div>
                                    )}
                                  </Droppable> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DragDropContext>

                      {/* <!-- Simple Json Path Modal --> */}
                      <CommonFieldConfig
                        modalTitle={t(
                          "fieldConfiguration.fieldConfigPopup.title"
                        )}
                        handleLevelClick={handleLevelClick}
                        modalDataState={modalData}
                        setModalDataState={setModalData}
                        handleSave={handleSave}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        showGroupFields={false}
                        onlyGroupOptions={onlyGroupOptions}
                        ruleBuilderFields={ruleBuilderFields}
                        mappedFieldJson={mappedFieldJson}
                      />

                      <CommonFieldConfig
                        modalTitle={t(
                          "fieldConfiguration.fieldConfigPopup.title"
                        )}
                        // handleLevelClick={handleLevelClick}
                        modalDataState={groupModalData}
                        setModalDataState={setGroupModalData}
                        handleSave={handleGroupSave}
                        showGroupFields={true}
                        showModal={showGroupModal}
                        setShowModal={setShowGroupModal}
                      />
                    </div>

                    <div className="row justify-content-end m-auto pt-3">
                      <PrimaryButton
                        title={t("CommonKeys.saveButtonTitle")}
                        icon="fa-regular fa-floppy-disk"
                        disabled={
                          searchParams.get("save") === "1"
                            ? false
                            : originalGroupData === JSON.stringify(groupData) ||
                              isSavingData
                        }
                        onClick={() => {
                          saveFields();
                          // navigate(getPathPrefix("dashboard"));
                          /* navigate(
                            `/ManageCompany/ViewCompany/${params?.companyId}?activeTab=datastream`
                          ); */
                        }}
                      />
                      <PrimaryButton
                        title={t("CommonKeys.backButtonTitle")}
                        className="ms-2"
                        icon="fa-solid fa-arrow-left"
                        onClick={() => {
                          if (originalGroupData !== JSON.stringify(groupData)) {
                            setShowDelete(true);
                            setGoToBack(true);
                          } else {
                            navigate(getPathPrefix("dashboard"));
                          }
                          /* navigate(
                            `/ManageCompany/ViewCompany/${params?.companyId}?activeTab=datastream`
                          ); */
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldConfigJson;
