import { useState } from "react";
import { InputGroup } from "react-bootstrap";

const PasswordInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  disabled,
  initValidation,
  rightIcon,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="form-group">
      <label className="mb-2 form-label">
        {label}
        {required && (
          <sup>
            <i
              className="fa-solid fa-star-of-life"
              style={{
                color: "red",
                fontSize: "5px",
                marginBottom: "5px",
              }}
            ></i>
          </sup>
        )}
      </label>
      <div className="form-group">
        <div className="input-group">
          {rightIcon && <InputGroup.Text>{rightIcon}</InputGroup.Text>}
          <input
            {...field}
            {...{ ...props, required }}
            type={showPassword ? "text" : "password"}
            disabled={isSubmitting || disabled}
            className="form-control"
          />
          <span
            className="input-group-text bg-transparent pe-0"
            style={{ width: "40px", cursor: "pointer" }}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <i
              className={`${
                !showPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"
              }`}
            ></i>
          </span>
        </div>
        {(initValidation
          ? errors[field.name]
          : touched[field.name] && errors[field.name]) && (
          <h6 className="text-danger mt-1 input-error-text">
            {errors[field.name]}
          </h6>
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
