import React from "react";

const RightPanelHeader = ({ title = "", subTitle = "" }) => {
  return (
    <>
      {/*<div className="logo">*/}
      {/*  <img*/}
      {/*    src={IMAGEPATH.ai_cost_control_logo}*/}
      {/*    alt=""*/}
      {/*    className="img-fluid"*/}
      {/*  />*/}
      {/*</div>*/}
      {(title || subTitle) && (
        <div className="heading">
          {title && <h4>{title}</h4>}
          {subTitle && <h6>{subTitle}</h6>}
        </div>
      )}
    </>
  );
};

export default RightPanelHeader;
