import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import RightPanelHeader from "../../components/rightPanelHeader.component";
import PrimaryButton from "../../../../components/Form/Buttons/PrimaryButton";
import OtpInput from "react-otp-input";
import { getPathPrefix } from "../../../../utils/coreUtils";
import companyUserApi from "../../../../services/apis/companyUserApi";
import { toast } from "react-toastify";

import "./ChangePass.css";
import "../Otp.css";
import ToastUtils from "../../../../utils/ToastUtils";

const VeirfyResetPasswordOtp = () => {
  const [t] = useTranslation();
  const [verificationOtp, setVerificationOtp] = React.useState("");
  const navigate = useNavigate();

  // for resend Otp
  const minutes = 0;
  const seconds = 45;
  const [[mins, secs], setTime] = React.useState([minutes, seconds]);
  const [isSendingOtp, setIsSendingOtp] = React.useState(false);

  const initialFormValues = {
    veirfyResetPassOtp: "",
  };

  // for resend Opt
  const tick = () => {
    if (secs > 0) {
      setTime([mins, secs - 1]);
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [mins, secs]);

  const reset = () => setTime([parseInt(minutes), parseInt(seconds)]);

  const resendOtp = async () => {
    setIsSendingOtp(true);
    try {
      await companyUserApi.generateOtpChangePassword({}).then((res) => {
        toast.success(res.data.message);
      });
      reset();
    } catch (error) {
      /* empty */
    }
    setIsSendingOtp(false);
  };

  const handleSubmit = async () => {
    await companyUserApi
      .otpVerificationChangePassword({ otp: verificationOtp })
      .then((res) => {
        // toast.success(res.data.message);
        if (res.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${res?.data?.code}`));
        }
        if (res.data.status === "200") {
          navigate(getPathPrefix() + `change-password-reset`, {
            state: { Otp: verificationOtp },
            replace: true,
          });
        }
      });
  };

  return (
    <section
      className="page main-signin-wrapper container-layout align-items-center otp-page main-password-change"
      style={{
        backgroundColor: "rgb(240 246 247)",
      }}
    >
      <div className="row bg-white card-view">
        <div className="col-lg-12">
          <div className="form-container">
            <RightPanelHeader
              title={t("otpHints.checkEmailLabel")}
              subTitle={t("otpHints.enterOtpText")}
            />
            <Formik
              initialValues={initialFormValues}
              validateOnMount
              className="mt-2"
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                // values,
                // errors,
                // touched,
                // handleChange,
                // handleBlur,
                handleSubmit,
                // isValid,
                // isSubmitting,
                // dirty,
                // ...props
              }) => (
                <Form onSubmit={handleSubmit} className="form-view">
                  <Field
                    render={() => (
                      <OtpInput
                        containerStyle={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "310px",
                          marginLeft: "-4px",
                        }}
                        value={verificationOtp}
                        onChange={setVerificationOtp}
                        numInputs={6}
                        separator={<span> </span>}
                        className="input-style mb-4 mx-auto"
                        shouldAutoFocus
                      />
                    )}
                    id="veirfyResetPassOtp"
                    name="veirfyResetPassOtp"
                  />

                  <div className="form-group text-center">
                    {`${secs.toString().padStart(2)}` > 0 ? (
                      <b style={{ color: "#004e5c" }}>
                        {mins.toString().padStart(2, "0")} :{" "}
                        {secs.toString().padStart(2, "0")}
                      </b>
                    ) : isSendingOtp ? (
                      <b className="cursor-pointer">
                        {t("CommonKeys.sendingText")}
                      </b>
                    ) : (
                      <b
                        className="cursor-pointer"
                        onClick={() => {
                          resendOtp();
                        }}
                      >
                        {t("CommonKeys.resendOtpText")}
                      </b>
                    )}
                  </div>

                  <div className="row justify-content-end mb-3">
                    <PrimaryButton
                      type="submit"
                      title={t("CommonKeys.submitButtonTitle")}
                      className="btn btn-green w-auto w-75"
                    />
                    <PrimaryButton
                      title={t("CommonKeys.cancelButtonTitle")}
                      className="btn btn-green w-auto w-75"
                      onClick={() => {
                        navigate(getPathPrefix("dashboard"), {
                          replace: true,
                        });
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VeirfyResetPasswordOtp;
