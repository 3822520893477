import React from "react";

function AddField() {
  return (
    <div className="container-scroller">
      {/* <!-- partial --> */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* <!-- partial --> */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">Field Configuaration</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Field Configuaration
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Field
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!-- breadcrumb-end --> */}
            <div className="col-12 col-lg-8 col-md-12 mx-auto">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="card-label mb-0">Add Field</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Path
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Path"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Source
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Source"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Index
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Index"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Group
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Type
                          </label>
                          <select className="form-select">
                            <option selected>Select</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-4">
                      <a href="#" type="button" className="btn">
                        <i className="fa-regular fa-floppy-disk me-2"></i>Save
                      </a>
                      <a href="#" type="button" className="btn ms-md-3">
                        <i className="fa-solid fa-xmark me-2"></i>Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
          {/* <!-- partial:partials/_footer.html --> */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
          {/* <!-- partial --> */}
        </div>
        {/* <!-- main-panel ends --> */}
      </div>
      {/* <!-- page-body-wrapper ends --> */}
    </div>
  );
}

export default AddField;
