import { t } from "i18next";
import CustomModal from "../../../components/Modals/CustomModal";
const PopUpModel = ({ show, onClose, item }) => {
  const popUpBody = () => {
    return (
      <div>
        <div className="pop-up-msg">
          {/* The "{item?.fieldName}" Field is used in one of the Rule. It can not
          be deleted */}
          {t("fieldConfiguration.notAllowedToDeletePopup.message", {
            fieldName: item?.fieldName,
          })}
        </div>
      </div>
    );
  };
  return (
    <CustomModal
      title={t("fieldConfiguration.notAllowedToDeletePopup.title")}
      show={show}
      handleClose={onClose}
      body={popUpBody()}
      centered
      okTitle="Ok"
      onOk={onClose}
    />
  );
};
export default PopUpModel;
