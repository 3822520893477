import React from "react";

function Routing() {
  return (
    <>
      <div className="container-scroller">
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
                <div className="breadcrumb-title pe-3">Estimate</div>
                <div className="ps-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Routing
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <div className="card-label">
                          <img
                            src="/assets/images/routing.svg"
                            alt=""
                            className="me-2"
                          />{" "}
                          Routing
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <div className="d-block d-md-flex justify-content-end">
                          <a href="#" type="button" className="btn">
                            <i className="fa-solid fa-plus me-2"></i>Add Routing
                          </a>
                          <a href="#" type="button" className="btn ms-md-3">
                            <i className="fa-regular fa-square-plus me-2"></i>
                            Create Active Version
                          </a>
                          <a href="#" type="button" className="btn ms-md-3">
                            <i className="fa-sharp fa-solid fa-rotate me-2"></i>
                            Change Name
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive">
                    <table className="table table-modern">
                      <thead>
                        <tr>
                          <td width="20px"></td>
                          <th>Version</th>
                          <th>Created Date/Time</th>
                          <th>Changed Date/Time</th>
                          <th>Edit By</th>
                          <th>Active</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-check form-check-sm form-check-custom">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              className="text-primary text-decoration-underline"
                            >
                              Routing 5
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>Yes</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i className="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check form-check-sm form-check-custom">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              className="text-primary text-decoration-underline"
                            >
                              Routing 3
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>No</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i className="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check form-check-sm form-check-custom">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              className="text-primary text-decoration-underline"
                            >
                              Routing 1
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>Yes</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i className="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check form-check-sm form-check-custom">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              className="text-primary text-decoration-underline"
                            >
                              Routing 2
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>No</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i className="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </>
  );
}

export default Routing;
